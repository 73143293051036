<button mat-icon-button [matMenuTriggerFor]="helpMenu" #helpMenuTrigger="matMenuTrigger">
    <mat-icon>help</mat-icon>
</button>

<mat-menu #helpMenu="matMenu" [overlapTrigger]="false" class="toolbar-dropdown-menu help-menu">
    <!--<span (mouseleave)="helpMenuTrigger.closeMenu()">-->
    <mat-toolbar color="primary">
        <div fxLayout="row" fxLayoutAlign="space-around center" class="help-info">
            <p>HELP</p>
        </div>
    </mat-toolbar>
    <!--</span>-->
</mat-menu>
