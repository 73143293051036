import {Inject, Injectable} from '@angular/core';

import {Menu} from './menu.model';
import {horizontalMenuItems, verticalMenuItems} from './menu-initial-structure';
import {Group} from '../../../domain';

@Injectable({
    providedIn: 'root'
})
export class MenuService {
    items: Menu[] = [];

    constructor(@Inject('Location') private location: Location) {
        // console.log('MenuService()', new Date());

        this.items = Object.assign([], verticalMenuItems);
        const parent = this.items.filter(current => current.title === 'Groups')[0];
    }

    public getVerticalMenuItems(): Array<Menu> {
        // console.log('getVerticalMenuItems()', new Date());
        return this.items;
    }

    public getHorizontalMenuItems(): Array<Menu> {
        return horizontalMenuItems;
    }

    public expandActiveSubMenu(menu: Array<Menu>) {
        const url = this.location.href;
        const routerLink = url; // url.substring(1, url.length);
        const activeMenuItem = menu.filter(item => item.routerLink === routerLink);
        if (activeMenuItem[0]) {
            let menuItem = activeMenuItem[0];
            while (menuItem.parentId !== 0) {
                const parentMenuItem = menu.filter(item => item.id === menuItem.parentId)[0];
                menuItem = parentMenuItem;
                this.toggleMenuItem(menuItem.id);
            }
        }
    }

    public toggleMenuItem(menuId) {
        const menuItem = document.getElementById('menu-item-' + menuId);
        const subMenu = document.getElementById('sub-menu-' + menuId);
        if (subMenu) {
            if (subMenu.classList.contains('show')) {
                subMenu.classList.remove('show');
                menuItem.classList.remove('expanded');
            } else {
                subMenu.classList.add('show');
                menuItem.classList.add('expanded');
            }
        }
    }

    public closeOtherSubMenus(menu: Array<Menu>, menuId) {
        const deploymentsMenuId = 2;
        if (menuId !== deploymentsMenuId) {
            const currentMenuItem = menu.filter(item => item.id === menuId)[0];
            if (currentMenuItem.parentId === 0 && !currentMenuItem.target) {
                menu.forEach(item => {
                    if (item.id !== menuId) {
                        const subMenu = document.getElementById('sub-menu-' + item.id);
                        const menuItem = document.getElementById('menu-item-' + item.id);
                        if (subMenu) {
                            if (subMenu.classList.contains('show')) {
                                subMenu.classList.remove('show');
                                menuItem.classList.remove('expanded');
                            }
                        }
                    }
                });
            }
        }
    }

    public removeFromMenuItems(group: Group){
        const existingDeleted = this.items.find(g => g.groupId === group.id);
        const indexOfExistingDeleted = this.items.indexOf(existingDeleted);

        this.items.splice(indexOfExistingDeleted, 1);
    }

    public addOrUpdateToMenu(group: Group) {
        // if (!group.id) {
        //     return;
        // }

        const menuItems = this.items;

        const GROUPS_ID = 30;
        const newMenuItem = new Menu(null, group.name, group.name,
            '/deployments/group/' + group.name, null, 'last_page',
            null, false, GROUPS_ID, group.id);

        const foundSome = menuItems.some((m: Menu) => {
            return m.groupId === newMenuItem.groupId;
        });

        if (!foundSome) {
            menuItems.push(newMenuItem);
        }

        if (foundSome) {
            const index = menuItems.findIndex(mi => {
                return mi.groupId === newMenuItem.groupId;
            });

            menuItems[index] = newMenuItem;
        }

        const item = menuItems.filter(current => current.id === newMenuItem.parentId)[0];
        if (item) {
            item.hasSubMenu = true;
        }
    }
}
