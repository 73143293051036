import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { from } from 'rxjs/index';
import { filter, map, tap } from 'rxjs/operators';

import { AppConfigService } from '../_app-config/app-configuration.service';
import { ApplicationUser } from '../domain/application-user';
import { Store } from '@ngrx/store';

import * as fromRoot from '../state/app.state';
import * as userActions from '../state/user.actions';
import { ANONYMOUS_USER } from '../domain/constants';

import {
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import { Router } from '@angular/router';

const POOL_DATA = {
  UserPoolId: 'eu-west-2_57os06nFL',
  ClientId: '7p8aohc74qnq9n0pvc3viug4d8',
};
const userPool = new CognitoUserPool(POOL_DATA);

export interface AuthDidFailPayload {
  status: boolean;
  message: string;
}

@Injectable()
export class AuthService {
  private readonly BASE_API_URL: string;
  private subjectCurrentAppUser = new BehaviorSubject<ApplicationUser>(
    this.getApplicationUser()
  );
  private registeredUser: CognitoUser;

  authIsLoading = new BehaviorSubject<boolean>(false);
  authDidFail = new BehaviorSubject<AuthDidFailPayload>({
    status: undefined,
    message: 'initialised',
  });
  authStatusChanged = new Subject<boolean>();

  currentUser$: Observable<
    ApplicationUser
  > = this.subjectCurrentAppUser.asObservable().pipe(
    filter((user) => !!user),
    tap((user) => {
      if (!!user) {
        this.store.dispatch(new userActions.SetCurrentUser(user));
      }
    })
  );

  errors$: Subject<string> = new Subject();

  isLoggedIn$: Observable<boolean> = this.subjectCurrentAppUser.pipe(
    filter((u) => !!u),
    tap((u) => console.log('subjectCurrentAppUser has been updated : ', u)),
    map((user) => !!user.userId)
  );

  isLoggedOut$: Observable<boolean> = this.subjectCurrentAppUser.pipe(
    map((user) => !user.userId)
  );

  constructor(
    private http: HttpClient,
    public appConfigService: AppConfigService,
    private store: Store<fromRoot.AppState>,
    private router: Router
  ) {
    // set the required local data
    this.BASE_API_URL = appConfigService._baseUrl;

    // const applicationUser = <ApplicationUser>appConfigService.getConfig().user;
    // if (!applicationUser) {
    //     throw new Error('Could not determine the ApplicationUser inside AuthService');
    // }
    // this.subjectCurrentAppUser.next(applicationUser);
  }

  signUp(username: string, email: string, password: string): void {
    this.authIsLoading.next(true);
    const user: ApplicationUser = {
      userId: username,
      email: email,
      password: password,
    } as ApplicationUser;
    const attrList: CognitoUserAttribute[] = [];
    const emailAttribute = {
      Name: 'email',
      Value: user.email,
    };
    attrList.push(new CognitoUserAttribute(emailAttribute));
    userPool.signUp(
      user.userId,
      user.password,
      attrList,
      null,
      (err, result) => {
        if (err) {
          this.authDidFail.next({ status: true, message: err.message });
          this.authIsLoading.next(false);
          console.log(err);
          return;
        }
        this.authDidFail.next({
          status: false,
          message: `You have successfuly signed up with the username: ${user.userId}.
          You will now receive an email with a code that you can use to confirm your account.`,
        });
        this.authIsLoading.next(false);
        this.registeredUser = result.user;
      }
    );
    return;
  }

  confirmUser(username: string, code: string) {
    this.authIsLoading.next(true);
    const userData = {
      Username: username,
      Pool: userPool,
    };
    const cognitUser = new CognitoUser(userData);
    cognitUser.confirmRegistration(code, true, (err, result) => {
      if (err) {
        this.authDidFail.next({ status: true, message: err.message });
        this.authIsLoading.next(false);
        console.log(err);
        return;
      }
      this.authDidFail.next({
        status: false,
        message: 'Your account has now been confirmed. You can now Log in.',
      });
      this.authIsLoading.next(false);
    });
  }

  signIn(username: string, password: string): void {
    this.authIsLoading.next(true);
    const authData = {
      Username: username,
      Password: password,
    };
    const authDetails = new AuthenticationDetails(authData);
    const userData = {
      Username: username,
      Pool: userPool,
    };
    const cognitoUser = new CognitoUser(userData);
    const that = this;
    cognitoUser.authenticateUser(authDetails, {
      onSuccess(result: CognitoUserSession) {
        that.authStatusChanged.next(true);
        that.authDidFail.next({
          status: false,
          message: 'You have successfully logged in.',
        });
        that.authIsLoading.next(false);
        console.log(result);
 
        that.subjectCurrentAppUser.next(that.getApplicationUser());

        that.router.navigate(['/']);
      },
      onFailure(err) {
        that.authDidFail.next({
          status: true,
          message:
            'You failed to signed in. Please check your credentials. Have you verified your account?',
        });
        that.authIsLoading.next(false);
        console.log(err);
      },
    });
    this.authStatusChanged.next(true); // create user with cognito data
    return;
  }

  getAuthenticatedUser() {
    return userPool.getCurrentUser();
  }

  getApplicationUser() {
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser) {
      const applicationUser = new ApplicationUser();

      applicationUser.userId = cognitoUser.getUsername();
      applicationUser.firstName = 'Byron';
      applicationUser.lastName = 'Thanopoulos';
      applicationUser.icon = 'assets/img/icon.jpg';


      return applicationUser;
    } else {
      return ANONYMOUS_USER;
    }
  }

  isAuthenticated(): Observable<boolean> {
    const user = this.getAuthenticatedUser();
    const obs = new Observable<boolean>((observer) => {
      if (!user) {
        observer.next(false);
      } else {
        user.getSession((err, session) => {
          if (err) {
            observer.next(false);
          } else {
            if (session.isValid()) {
              observer.next(true);
            } else {
              observer.next(false);
            }
          }
        });
      }
      observer.complete();
    });
    return obs;
  }

  logout(): Observable<ApplicationUser> {
    const cognitoUser = this.getAuthenticatedUser();
    if (cognitoUser) {
      cognitoUser.signOut();
      this.authDidFail.next({
        status: false,
        message: 'You have successfully logged out.',
      });
    }
    this.subjectCurrentAppUser.next(ANONYMOUS_USER);

    return from([ANONYMOUS_USER]);
  }
}
