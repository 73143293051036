/* NgRx */
import {Action} from '@ngrx/store';
import {DeploymentsQuery} from '../../../domain';


export enum SearchActionTypes {
    DeploymentsSearch = '[Shell] Search',
    DeploymentsSearchQuerySet = '[Shell] Search'
}

// Action Creators
export class DeploymentsSearch implements Action {
    readonly type = SearchActionTypes.DeploymentsSearch;

    constructor(public payload: DeploymentsQuery) {
    }
}

export class DeploymentsSearchQuerySet implements Action {
    readonly type = SearchActionTypes.DeploymentsSearchQuerySet;

    constructor(public payload: DeploymentsQuery) {
    }
}


// Union the valid types
export type SearchActions = DeploymentsSearch
    | DeploymentsSearchQuerySet;

