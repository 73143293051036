import {AfterViewInit, Component, ElementRef, Inject, InjectionToken, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export const Window = new InjectionToken('Window');

@Component({
    selector: 'app-launch-modal',
    template: `
        <form [formGroup]="launchActionForm"
              (ngSubmit)="onSubmit(launchActionForm, $event)"
              disabled
              [action]="action"
              target="_blank"
              method="post"
              #form
        >
            <h1 mat-dialog-title><span>{{entity}}</span></h1>
            <div mat-dialog-content>

                <div>
                    <div>
                        <div>
                            <div class="form-group">
                                <!-- <core-spinner></core-spinner>-->
                                <label> Please Wait while we log you on...</label>
                                <div class="input-group">
                                    <input type="text"
                                           [value]="entity"
                                           class="form-control"
                                    >
                                    <input type="hidden"
                                           [value]="entity"
                                           name="username"
                                           id="username"
                                           formControlName="username"
                                           #userNameHiddenInputElement
                                           class="form-control"
                                    >
                                    <input type="password"
                                           [value]="entity"
                                           name="password"
                                           id="password"
                                           formControlName="password"
                                           #passwordHiddenInputElement
                                           class="form-control"
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between center">
                <button mat-raised-button color="warn" #closeButton (click)="closeModal()">Cancel</button>
                <button mat-raised-button color="primary"
                        type="submit" #submitButton
                        [disabled]="!launchActionForm.valid"><span>Launch</span></button>

            </div>
        </form>

    `,
    styleUrls: ['./launch-deployment-modal.component.scss'],
    providers: []
})
export class LaunchDeploymentModalComponent implements OnInit, OnDestroy, AfterViewInit {

    /*The reason is that mat-button is an angular component
     (which itself is a directive) and ViewChild returns the whole component if you do not specify the read option*/
    @ViewChild('submitButton', {read: ElementRef}) // We will have a template reference variable: #submitButton
    submitButton: ElementRef;

    @ViewChild('closeButton', {read: ElementRef}) // We will have a template reference variable: #closeButton
    closeButton: ElementRef;

    public action: string;

    public launchActionForm: FormGroup = new FormGroup({
        username: new FormControl(this.entity, []),
        password: new FormControl(this.entity, [])
    });

    constructor(public dialogRef: MatDialogRef<LaunchDeploymentModalComponent>,
                @Inject(MAT_DIALOG_DATA) public entity: { url: string },
                private renderer: Renderer2) {
                    if (this.entity) {
                        this.action = this.entity.url;
                     }
    }

    ngOnInit() {

    }

    ngAfterViewInit() {
        if (this.entity) {
            this.submitButton.nativeElement.click();
        }
    }

    ngOnDestroy() {

    }

    onSubmit(launchActionForm: FormGroup, e: any) {
        console.log(this.entity);
        console.log(launchActionForm);

        e.target.submit();
        this.closeModal();
    }

    closeModal() {
        this.dialogRef.close('Close click');
    }
}// class
