<mat-tab-group>
  <mat-tab>
    <ng-template mat-tab-label>
      Login &nbsp;<mat-icon 
      matTooltip="Click to see how you can easily register with AWS cognito and then login."
      (click)="openBottomSheet()"
      class="yellow">info</mat-icon>
    </ng-template>
    <mat-card>
      <img mat-card-image src="/assets/img/icode.net logo.png" alt="Icode.net">
      <mat-card-title>Icode Login</mat-card-title>
      <mat-card-content>
    
        <div>
          <form (ngSubmit)="onSubmitLogin(loginForm.value)" [formGroup]="loginForm" >
            <p>
              <mat-form-field>
                <input type="text" autocomplete="off" required matInput placeholder="Username" formControlName="username">
              </mat-form-field>
            </p>
    
            <p>
              <mat-form-field>
                <input type="password" required matInput placeholder="Password" formControlName="password">
              </mat-form-field>
            </p> 
    
            <div class="button">
              <button mat-button type="submit" [disabled]="loginForm.invalid">Submit</button>
            </div>
          </form>
          <div class="loader" *ngIf="isLoading"></div>
        </div>
    
    
      </mat-card-content>
    </mat-card>
  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      Sign Up
    </ng-template>
    <mat-card>
      <img mat-card-image src="/assets/img/icode.net logo.png" alt="Icode.net">
      <mat-card-title>Icode Signup</mat-card-title>
      <mat-card-content>
    
        <div>
          <form (ngSubmit)="onSubmitRegistration(userRegistrationForm.value)"  [formGroup]="userRegistrationForm" >
            <p>
              <mat-form-field>
                <input type="text" required autocomplete="off"  matInput placeholder="Username" formControlName="username">
              </mat-form-field>
            </p>
    
            <p>
              <mat-form-field>
                <input type="email" required autocomplete="off"  matInput placeholder="Email" formControlName="email">
              </mat-form-field>
            </p>
    
            <p>
              <mat-form-field>
                <input type="password" required matInput placeholder="Password" formControlName="password">
              </mat-form-field>
            </p> 
    
            <p *ngIf="userRegistrationForm.value.password !== userRegistrationForm.value.confirmPassword">Passwords do not match!</p>

            <p>
              <mat-form-field>
                <input type="password" required matInput placeholder="Confirm Password" formControlName="confirmPassword">
              </mat-form-field>
            </p> 
    
            <div class="button">
              <button mat-button type="submit" 
              [disabled]="(userRegistrationForm.value.password !== userRegistrationForm.value.confirmPassword) || userRegistrationForm.invalid" >Submit</button>
            </div>
          </form>
          <div class="loader" *ngIf="isLoading"></div>
        </div>
    
    
      </mat-card-content>
    </mat-card>
  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      Confirm User
    </ng-template>
    <mat-card>
      <img mat-card-image src="/assets/img/icode.net logo.png" alt="Icode.net">
      <mat-card-title>Icode User Validation</mat-card-title>
      <mat-card-content>
    
        <div>
          <form (ngSubmit)="onConfirm(confirmForm.value)" [formGroup]="confirmForm" >
            <p>
              <mat-form-field>
                <input type="text" required autocomplete="off"  matInput placeholder="Username" formControlName="validationUsername">
              </mat-form-field>
            </p>
    
            <p>
              <mat-form-field>
                <input type="text" required matInput placeholder="Validation Code" formControlName="validationCode">
              </mat-form-field>
            </p> 
    
            <div class="button">
              <button mat-button type="submit" [disabled]="confirmForm.invalid">Confirm your Account</button>
            </div>
          </form>
          <div class="loader" *ngIf="isLoading"></div>
        </div>
    
    
      </mat-card-content>
    </mat-card>
  </mat-tab>
</mat-tab-group>
