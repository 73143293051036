export class DeploymentsQuery {

    public depoymentId: number = null;

    public pageNumber = 0;
    public pageSize = 3;

    public sortOrder = 'ASC';
    public sortBy: string = null;

    public searchBy: string = null;
    public searchTerm: string = null;

}

