/* NgRx */
import {Action} from '@ngrx/store';
import {ApplicationUser} from '../domain';

export enum UserActionTypes {
  ShowUserEmail = '[User] Show User Email',
  SetCurrentUser = '[User] Set Current User'
}

export class ShowUserEmail implements Action {
  readonly type = UserActionTypes.ShowUserEmail;

  constructor(public payload: boolean) {
  }
}

export class SetCurrentUser implements Action {
  readonly type = UserActionTypes.SetCurrentUser;

  constructor(public payload: ApplicationUser) {
  }
}

export type UserActions = ShowUserEmail | SetCurrentUser;
