export class ApplicationUser {

  public email: string;
  public icon: string;
  public firstName: string;
  public lastName: string;
  public fullName: string;
  public userId: string;
  public password?: string;
  public entitlements: {
    'read': string[],
    'write': string[],
    'roles': string[],
    'gesNamespace': string,
    'gesNamespacePath': string
  };

}

