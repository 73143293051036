
export class Deployment {
    id: string;
    name: string;
    client: string;
    clientUrl: string;
    groupName: string;
    logo: string;
    location: string;
    startDate: string;
    endDate: string;
    renewals: number;
    completed: boolean;
    description: string;
    role: string;
    applicationDomain: string;
    responsibilities: string;
    skills: string[];
}

export class Group {
    public id: number;
    public name: string;

    constructor(id?: number, name?: string) {
        if (id) {
            this.id = id;
        }
        if (name) {
            this.name = name;
        }
    }
}
