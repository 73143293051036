<button mat-icon-button [matMenuTriggerFor]="userMenu" #userMenuTrigger="matMenuTrigger">
    <mat-icon>account_circle</mat-icon>
</button>

<mat-menu #userMenu="matMenu"  [overlapTrigger]="false" class="toolbar-dropdown-menu user-menu">
    <!--<span (mouseleave)="userMenuTrigger.closeMenu()">-->
    <mat-toolbar color="primary">
        <div fxLayout="row" fxLayoutAlign="space-around center" class="user-info">
            <img [src]="userImageUrl" #userImage alt="user-image" height="80px" class="rounded mat-elevation-z6">
            <p>{{ user.firstName }}&nbsp; {{ user.lastName }} <br>
                <small>{{ user.userId }}</small>
            </p>
        </div>
    </mat-toolbar>
    <a mat-menu-item>
        <mat-icon>person</mat-icon>
        <span>Name</span>
        <span class="p-1 muted-text">{{ user.firstName }}&nbsp; {{ user.lastName }}</span>
    </a>
    <a mat-menu-item>
        <mat-icon>email</mat-icon>
        <span>Email</span>
        <span class="p-1 muted-text">{{ user.email }}</span>
    </a>
    <div class="divider"></div>
    <a mat-menu-item *ngFor="let role of user?.entitlements?.roles">
        <mat-icon>security</mat-icon>
        <span>Role</span>
        <span class="p-1 muted-text">{{ role }}</span>
    </a>
    <div class="divider"></div>
    <p><button mat-raised-button color="warn" (click)="logout()">Logout</button></p>
    <!--</span>-->
</mat-menu>
