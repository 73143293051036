/* NgRx */
import {Action} from '@ngrx/store';
import {DeploymentsQuery, Group} from '../../../domain';

export enum GroupActionTypes {
    DeploymentGroupsLoad = '[Deployment View] Groups Load',
    DeploymentGroupsLoadSuccess = '[Deployment View] Groups Load Success',
    DeploymentGroupsUpdateSuccess = '[Deployment View] Groups Update Success',
    DeploymentGroupsLoadFail = '[Deployment View] Groups Load Fail',
    DeploymentGroupsUpdatedFail = '[Deployment View] Group Update Fail',
    DeploymentGroupCreated = '[Deployment View] Group Created',
    DeploymentGroupUpdated = '[Deployment View] Group Updated',
    GroupCreated = '[Groups View] Group Cretaed',
    GroupCreatedSuccess = '[Groups View] Group Created Success',
    GroupCreatedFail = '[Groups View] Group Created Fail',
    GroupDeleted = '[Groups View] Group Deleted',
    GroupDeletedSuccess = '[Groups View] Group Deleted Success',
    GroupDeletedFail = '[Groups View] Group Deleted Fail',
    GroupUpdated = '[Groups View] Group Updated',
    GroupsRefresh = '[Menu View] Groups Refresh'
}



export class DeploymentGroupsLoad implements Action {
    readonly type = GroupActionTypes.DeploymentGroupsLoad;
}

export class DeploymentGroupsLoadSuccess implements Action {
    readonly type = GroupActionTypes.DeploymentGroupsLoadSuccess;

    constructor(public payload: Group[]) {
    }
}

export class DeploymentGroupsUpdateSuccess implements Action {
    readonly type = GroupActionTypes.DeploymentGroupsUpdateSuccess;

    constructor(public payload: number) {
    }
}

export class DeploymentGroupsLoadFail implements Action {
    readonly type = GroupActionTypes.DeploymentGroupsLoadFail;

    constructor(public payload: string) {
    }
}

export class DeploymentGroupsUpdatedFail implements Action {
    readonly type = GroupActionTypes.DeploymentGroupsUpdatedFail;

    constructor(public payload: string) {
    }
}

export class DeploymentGroupCreated implements Action {
    readonly type = GroupActionTypes.DeploymentGroupCreated;

    constructor(public payload: Group) {
    }
}

export class DeploymentGroupUpdated implements Action {
    readonly type = GroupActionTypes.DeploymentGroupUpdated;

    constructor(public payload: Group) {
    }
}


export class GroupsRefresh implements Action {
    readonly type = GroupActionTypes.GroupsRefresh;

    constructor(public payload: Group[]) {
    }
}

export class GroupUpdated implements Action {
    readonly type = GroupActionTypes.GroupUpdated;

    constructor(public payload: Group) {
    }
}

export class GroupCreated implements Action {
    readonly type = GroupActionTypes.GroupCreated;

    constructor(public payload: Group) {
    }
}

export class GroupCreatedSuccess implements Action {
    readonly type = GroupActionTypes.GroupCreatedSuccess;

    constructor(public payload: Group) {
    }
}

export class GroupCreatedFail implements Action {
    readonly type = GroupActionTypes.GroupCreatedFail;

    constructor(public payload: string) {
    }
}


export class GroupDeleted implements Action {
    readonly type = GroupActionTypes.GroupDeleted;

    constructor(public payload: Group) {
    }
}

export class GroupDeletedSuccess implements Action {
    readonly type = GroupActionTypes.GroupDeletedSuccess;

    constructor(public payload: Group) {
    }
}

export class GroupDeletedFail implements Action {
    readonly type = GroupActionTypes.GroupDeletedFail;

    constructor(public payload: string) {
    }
}


// Union the valid types
export type GroupActions =    DeploymentGroupsLoad
    | DeploymentGroupsLoadSuccess
    | DeploymentGroupsLoadFail
    | DeploymentGroupCreated
    | DeploymentGroupUpdated
    | GroupCreated
    | GroupCreatedSuccess
    | GroupCreatedFail
    | GroupDeleted
    | GroupDeletedSuccess
    | GroupDeletedFail
    | GroupUpdated
    | GroupsRefresh;

