import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {OverlayContainer} from '@angular/cdk/overlay';
import {CustomOverlayContainer} from './theme/utils/custom-overlay-container';
import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {SharedModule} from './shared/shared.module';
import {routing} from './app.routing';
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {EffectsModule} from '@ngrx/effects';
import {RouterStateSerializer, StoreRouterConnectingModule} from '@ngrx/router-store';

import {AppComponent} from './app.component';
import {ShellComponent} from './views/shell.component';
import {ErrorComponent} from './views/errors/error/error.component';
import { SigninComponent } from './views/signin/signin.component';
import {AppSettings} from './app.settings';

import {SidenavComponent} from './theme/components/sidenav/sidenav.component';
import {VerticalMenuComponent} from './theme/components/menu/vertical-menu/vertical-menu.component';
import {HorizontalMenuComponent} from './theme/components/menu/horizontal-menu/horizontal-menu.component';
import {BreadcrumbComponent} from './theme/components/breadcrumb/breadcrumb.component';
import {FlagsMenuComponent} from './theme/components/flags-menu/flags-menu.component';
import {FullScreenComponent} from './theme/components/fullscreen/fullscreen.component';
import {ApplicationsComponent} from './theme/components/applications/applications.component';
import {UserMenuComponent} from './theme/components/user-menu/user-menu.component';
import {AppConfigService, LoadConfigurationFn} from './_app-config/app-configuration.service';
import {appReducers, metaReducers} from './state/app.reducer';
import {environment} from '../environments/environment';
import {CustomSerializer} from './state/utils';
import {AuthService} from './services';
import {HelpMenuComponent} from './theme/components/help-menu/help-menu.component';
import {GroupService} from './services/group.service';
import {MenuService} from './theme/components/menu/menu.service';
import {DeploymentsService} from './services/deployments.service';
import { AuthenticationGuard } from './services/authentication-guard.service';
import { CertificationsComponent } from './views/certifications/certifications.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    wheelPropagation: true,
    suppressScrollX: true
};

const INITIALISE_APP_PROVIDER = {
    provide: APP_INITIALIZER,
    useFactory: LoadConfigurationFn,
    deps: [AppConfigService],
    multi: true,
};

const PROVIDERS = [
    AuthService,
    AppConfigService,
    AppSettings,
    {provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG},
    {provide: OverlayContainer, useClass: CustomOverlayContainer},
    {provide: RouterStateSerializer, useClass: CustomSerializer},
    INITIALISE_APP_PROVIDER,
    {provide: 'Window', useValue: window},
    {provide: 'Location', useValue: location},
    GroupService,
    MenuService,
    DeploymentsService,
    AuthenticationGuard
];

const DECLARATIONS = [
    AppComponent,
    ShellComponent,
    ErrorComponent,
    SidenavComponent,
    VerticalMenuComponent,
    HorizontalMenuComponent,
    BreadcrumbComponent,
    FlagsMenuComponent,
    FullScreenComponent,
    ApplicationsComponent,
    UserMenuComponent,
    HelpMenuComponent,
    SigninComponent,
    CertificationsComponent
];


const ENTRY_COMPONENTS = [VerticalMenuComponent];


@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        PerfectScrollbarModule,
        SharedModule.forRoot(),
        routing,
        /**
         * StoreModule.forRoot is imported once in the root module, accepting a reducer
         * function or object map of reducer functions. If passed an object of
         * reducers, combineReducers will be run creating your application
         * meta-reducer. This returns all providers for an @ngrx/store
         * based application.
         */
        StoreModule.forRoot(appReducers, {metaReducers}),
        /**
         * @ngrx/router-store keeps router state up-to-date in the store.
         */
        StoreRouterConnectingModule.forRoot({
            /*
            They stateKey defines the name of the state used by the router-store reducer.
            This matches the key defined in the map of reducers
            */
            stateKey: 'router'
        }),
        /**
         * Store devtools instrument the store retaining past versions of state
         * and recalculating new states. This enables powerful time-travel
         * debugging.
         *
         * To use the debugger, install the Redux Devtools extension for either
         * Chrome or Firefox
         *
         * See: https://github.com/zalmoxisus/redux-devtools-extension
         */
        StoreDevtoolsModule.instrument({
            name: 'Our App Dev Tools',
            maxAge: 25,
            logOnly: environment.production
        }),
        /**
         * EffectsModule.forRoot() is imported once in the root module and
         * sets up the effects class to be initialized immediately when the
         * application starts.
         *
         * See: https://github.com/ngrx/platform/blob/master/docs/effects/api.md#forroot
         */
        EffectsModule.forRoot([]),
    ],
    declarations: [...DECLARATIONS],
    entryComponents: [...ENTRY_COMPONENTS],
    providers: [...PROVIDERS],
    bootstrap: [AppComponent]
})
export class AppModule {
}
