import {Menu} from './menu.model';

export const verticalMenuItems = [
    new Menu(2, 'Deployments', 'Frequently Accessed Deployments', '/deployments', null, 'access_time', null, false, 0),
    new Menu(30, 'Groups', 'Groups', null, null, 'group_work', null, true, 0),
    new Menu(32, 'Unassigned', 'Unassigned', '/deployments/nogroup', null, 'last_page', null, false, 30),
    new Menu(200, 'ANGULAR', 'ANGULAR', null, 'http://angular.io', 'open_in_new', '_blank', false, 0)
];

export const horizontalMenuItems = [
    new Menu(2, 'Deployments', 'Frequently Accessed Deployments', '/deployments', null, 'supervisor_account', null, false, 0),
    new Menu(30, 'Groups', 'Groups', null, null, 'view_module', null, true, 0),
    new Menu(32, 'Unassigned', 'Unassigned', '/deployments/nogroup', null, 'last_page', null, false, 30),
    new Menu(200, 'ANGULAR', 'ANGULAR', null, 'http://angular.io', 'open_in_new', '_blank', false, 40)
];
