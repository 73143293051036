import {Action} from '@ngrx/store';

export enum LayoutActionTypes {
    OpenSidenav = '[Layout] Open Sidenav',
    CloseSidenav = '[Layout] Close Sidenav',
    ToggleMenuVertical = '[Layout] Menu Vertical',
    SetMenuType = '[Layout] Menu Type',
    SetSkin = '[Layout] Set Skin',
    SetRtl = '[Layout] Set Rtl'
}

export class OpenSidenav implements Action {
    readonly type = LayoutActionTypes.OpenSidenav;
}

export class CloseSidenav implements Action {
    readonly type = LayoutActionTypes.CloseSidenav;
}

export class ToggleMenuVertical implements Action {
    readonly type = LayoutActionTypes.ToggleMenuVertical;

    constructor(public isMenuVertical: boolean) {
    }
}

export class SetMenuType implements Action {
    readonly type = LayoutActionTypes.SetMenuType;

    constructor(public menuType: string) {
    }
}

export class SetSkin implements Action {
    readonly type = LayoutActionTypes.SetSkin;

    constructor(public skin: string) {
    }
}

export class SetRtl implements Action {
    readonly type = LayoutActionTypes.SetRtl;

    constructor(public rtl: boolean) {
    }
}

export type LayoutActionsUnion =
    OpenSidenav |
    CloseSidenav |
    ToggleMenuVertical |
    SetMenuType |
    SetSkin |
    SetRtl;
