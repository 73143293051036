<mat-card class="example-card">
  <mat-card-header>
    <div mat-card-avatar class="example-header-image"></div>
    <mat-card-title>Registration Process and Steps</mat-card-title>

  </mat-card-header>
  <mat-card-content>
    <p>
      In order to be able to log in
      you have to register with us.
    </p>
    <p>The process is very simple and it will only take 1 minute tops.</p>
    <hr>
    <mat-list>
      <div mat-subheader>Steps</div>
      <mat-chip-list class='steps'>
        <mat-list-item>
          <div>
            <mat-chip color="primary" selected>Step 1:</mat-chip>
            Click on the "Sign Up" tab and supply the required information.
          </div>
        </mat-list-item>
        <mat-list-item>&nbsp;</mat-list-item>
        <mat-list-item>
          <div>
            <mat-chip color="primary" selected>Step 2:</mat-chip>
            Once you register (Sign Up) you will receive a code in your email. Use this code by heading into the
            "Confirm User" tab above and submit the code.
          </div>
        </mat-list-item>
        <mat-list-item>&nbsp;</mat-list-item>
        <mat-list-item>
          <div>
            <mat-chip color="primary" selected>Step 3:</mat-chip> Now that the previous two steps are complete you are
            able to login using the chosen user name and password from step 1.
          </div>
        </mat-list-item>
        <mat-list-item>&nbsp;</mat-list-item>
      </mat-chip-list>
    </mat-list>
  </mat-card-content>
</mat-card>