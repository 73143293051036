import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {ModuleWithProviders} from '@angular/core';

import {ShellComponent} from './views/shell.component';
import {ErrorComponent} from './views/errors/error/error.component';
import { AuthenticationGuard } from './services/authentication-guard.service';
import { SigninComponent } from './views/signin/signin.component';

export const routes: Routes = [
    {
        path: '',
        component: ShellComponent,
        canActivate: [AuthenticationGuard]
        , children: [
            {
                path: '', pathMatch: 'full', redirectTo: 'deployments'
            },
            {
                path: 'deployments', loadChildren: 'app/views/deployments.module#DeploymentsModule',
                data: {breadcrumb: 'Deployments'}
            }
        ]
    },
    {path: 'signin', component: SigninComponent, data: {breadcrumb: 'SignIn'}},
    {path: 'error', component: ErrorComponent, data: {breadcrumb: 'Error'}},
    {path: '**', component: ShellComponent}
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,  // <- comment this line for activate lazy load
    // useHash: true
});
