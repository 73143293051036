import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Deployment, Group} from '../../../domain';
import {GroupService} from '../../../services/group.service';
import {DeploymentsService} from '../../../services/deployments.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../state/app.state';
import {DeploymentGroupsLoad, GroupCreatedSuccess} from '../../state/actions/group.actions';



@Component({
    selector: 'app-group-create-dialog',
    templateUrl: './group-create-dialog.component.html',
    styleUrls: ['./group-create-dialog.component.scss'],
    providers: [GroupService]
})
export class GroupCreateDialogComponent implements OnInit {
    public form: FormGroup;
    public GROUP_MIN_LENGTH = 4;

    constructor(public dialogRef: MatDialogRef<GroupCreateDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public deploymentEntity: Deployment,
                public groupService: GroupService,
                private deploymentsService: DeploymentsService,
                public store: Store<AppState>,
                public fb: FormBuilder) {
        this.form = this.fb.group({
            id: null,
            name: [null, Validators.compose([Validators.required, Validators.minLength(this.GROUP_MIN_LENGTH)])]
        });
    }

    ngOnInit() {
        // GroupCreateDialogComponent
    }

    saveGroup(): void {
        const group = new Group();

        const id = this.form.controls['id'].value;
        const name = this.form.controls['name'].value;

        group.id = id;
        group.name = name;

        // todo Once service returns the group created we can chain the actions and not use the service here but canonically in the effects
        this.groupService.addEntity(group).subscribe(next => {
            console.log(next);
            this.store.dispatch(new DeploymentGroupsLoad());
            this.store.dispatch(new GroupCreatedSuccess(group));
        });
    }

    close(): void {
        this.dialogRef.close();
    }

}
