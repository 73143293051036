import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {GroupService} from '../../../services/group.service';
import {Group} from '../../../domain';
import {AppState} from '../../../state/app.state';
import {select, Store} from '@ngrx/store';
import {getGroups} from '../../state/user-portal.selectors';
import {TableElement} from '../../../domain/table-element';
import {GroupCreateDialogComponent} from '../group-create-dialog/group-create-dialog.component';
import {GroupDeleted, GroupUpdated} from '../../state/actions/group.actions';

@Component({
    selector: 'app-groups-edit-dialog',
    templateUrl: './groups-edit-dialog.component.html',
    styleUrls: ['./groups-edit-dialog.component.scss'],
    providers: [GroupService]
})
export class GroupsEditDialogComponent implements OnInit, AfterViewInit {
    public displayedColumns = ['id', 'name', 'actions'];
    public dataSource: MatTableDataSource<Group> = null;
    public groups: Group[];
    public elements: TableElement<Group>[];
    public editIndexId: number;

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(public dialogRef: MatDialogRef<GroupsEditDialogComponent>,
                public dialog: MatDialog,
                public store: Store<AppState>) {
    }

    ngOnInit() {

        this.store.pipe(select(getGroups)).subscribe((groups) => {
            this.groups = groups;
            this.elements = this.getRowsFromData(this.groups);

            this.dataSource = new MatTableDataSource<Group>(this.groups);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
        });
    }

    close(): void {
        this.dialogRef.close();
    }

    ngAfterViewInit() {

    }

    startEdit(group: Group): void {
        this.editIndexId = group.id;
    }

    stopEdit(group: Group): void {
        this.editIndexId = -1;
    }

    save(group) {
        console.log(group);

        const foundIdx = this.groups.indexOf(group);
        const updatedGroup = this.elements[foundIdx].currentData;

        const newGroups = this.groups.slice();
        newGroups[foundIdx] = updatedGroup;

        this.groups = newGroups;
        this.dataSource.data = newGroups;

        const action = new GroupUpdated(updatedGroup);
        this.store.dispatch(action);

        this.stopEdit(group);
    }

    delete(group) {
        console.log(group);
        const action = new GroupDeleted(group);
        this.store.dispatch(action);
    }

    updateElements(group, value) {
        console.log(group);
        console.log(value);

        const groupChanged = new Group(group.id, value);

        const foundIdx = this.groups.indexOf(group);

        this.elements[foundIdx].currentData = groupChanged;
    }

    private getRowsFromData(arrayData: Group[]): TableElement<Group>[] {
        return arrayData.map<TableElement<Group>>((data, index) => {

            return new TableElement({
                id: data.id,
                currentData: data
            });
        });
    }


    private setDataSource(data) {
        this.dataSource = new MatTableDataSource(data);
    }

    public openCreateGroupDialog() {
        const dialogRef = this.dialog.open(GroupCreateDialogComponent);

        dialogRef.afterClosed().subscribe(next => {
        });
    }

}
