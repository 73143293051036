import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {AppSettings} from '../../../app.settings';
import {Settings} from '../../../app.settings.model';
import {MenuService} from '../menu/menu.service';
import {ApplicationUser, Group} from '../../../domain';
import {AuthService} from '../../../services';
import {Menu} from '../menu/menu.model';
import {GroupService} from '../../../services/group.service';
import {AppState} from '../../../state/app.state';
import {Store} from '@ngrx/store';
import * as fromUserPortal from '../../../views/state/user-portal.selectors';
import {select} from '@ngrx/store';
import {DeploymentGroupsLoad, GroupsRefresh} from '../../../views/state/actions/group.actions';

@Component({
    selector: 'app-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: []
})
export class SidenavComponent implements OnInit, AfterViewInit {
    public userImageUrl = '../assets/img/deployments/default-user-icon.jpg';
    public menuItems: Menu[] = [];
    public settings: Settings;
    public items: Menu[] = [];
    public groups: Group[];
    public user: ApplicationUser;
    @ViewChild('userImage') private userImageEl: ElementRef;

    constructor(public appSettings: AppSettings,
                public menuService: MenuService,
                public groupService: GroupService,
                private store: Store<AppState>,
                private authService: AuthService,
                private changeDetectorRef: ChangeDetectorRef
    ) {
        this.settings = this.appSettings.settings;
        this.authService.currentUser$.subscribe((value) => {
            this.user = value;
        });
    }

    ngOnInit() {
        this.items = this.menuService.getVerticalMenuItems();
        this.menuItems = this.items;

        this.store.dispatch(new DeploymentGroupsLoad());

        this.store.pipe(select(fromUserPortal.getGroups))
            .subscribe((groups) => {
                this.groups = groups;
                    this.store.dispatch(new GroupsRefresh(this.groups));
                // this.changeDetectorRef.detectChanges();
            });
    }

    ngAfterViewInit(): void {
        // set the current user
        if (this.user.userId) {
            this.userImageEl.nativeElement.src =  this.user.icon;
        }
    }


    public closeSubMenus() {
        const menu = document.querySelector('.sidenav-menu-outer');
        if (menu) {
            for (let i = 0; i < menu.children[0].children.length; i++) {
                const child = menu.children[0].children[i];
                if (child) {
                    if (child.children[0].classList.contains('expanded')) {
                        child.children[0].classList.remove('expanded');
                        child.children[1].classList.remove('show');
                    }
                }
            }
        }
    }


    logout() {
        this.authService.logout();
    }

}
