import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'EntitySearchPipe', pure: false })
export class EntitySearchPipe implements PipeTransform {
    transform(value, args?): Array<any> {
        const searchText = new RegExp(args, 'ig');
        if (value) {
            const matched = value.filter(entity => {
                if (entity.name) {
                    return entity.name.search(searchText) !== -1;
                } else {
                    return false;
                }
            });

            return matched;
        }
    }
}
