// State for this feature (UserPortal)
import {PortalActions} from './portal.actions';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Deployment, DeploymentsQuery, Group, PaginationInfo} from '../../domain/index';
import * as fromRoot from '../../state/app.state';
import {SearchActionTypes} from './actions/search.actions';
import * as GroupActions from './actions/group.actions';
import * as deploymentActions from './actions/deployment.actions';

export interface UserPortalStateSlice {
    viewMode: string;
    currentDeploymentId: string | null;
    error: string;
    deploymentEntityState: DeploymentsState;
    search: SearchState;
    groups: Group[];
}

export interface UserPortalState extends fromRoot.AppState {
    userPortal: UserPortalStateSlice;
    search: SearchState;
}

export interface SearchState {
    query: DeploymentsQuery;
}

const initialSearchState: SearchState = {
    query: null
};

export interface DeploymentsState extends EntityState<Deployment> {
    loading: boolean;
    paginationInfo: PaginationInfo;
}

function sortById(l1: Deployment, l2: Deployment) {
    // const compare = l1.id - l2.id;
    // if (compare !== 0) {
    //     return compare;
    // } else {
    //     return l1.id - l2.id;
    // }
    return 0;
}

export const deploymentEntityAdapter: EntityAdapter<Deployment> =
    createEntityAdapter<Deployment>({
        sortComparer: sortById
    });

const initialDeploymentState = deploymentEntityAdapter.getInitialState({
    loading: false,
    paginationInfo: new PaginationInfo()
});

const initialUserPortalState: UserPortalStateSlice = {
    viewMode: 'LIST',
    currentDeploymentId: null,
    error: '',
    deploymentEntityState: initialDeploymentState,
    search: initialSearchState,
    groups: []
};


export function userPortalReducer(state = initialUserPortalState, action: PortalActions): UserPortalStateSlice {
    const deploymentEntityState = state.deploymentEntityState;
    const groupsCloned = [...state.groups];

    /* TODO : Now that the Domain of Actions is more or less know we need to have deterministic flow
    * So we need to only handle the <Action>Success Actions  in the Reducers
    * The Actual Actions Effects housl be handled in the <Action>Efects files*/

    switch (action.type) {

        case SearchActionTypes.DeploymentsSearch:
            return {
                ...state,
                search: {query: action.payload}
            };

        case SearchActionTypes.DeploymentsSearchQuerySet:
            return {
                ...state,
                search: {query: action.payload}
            };

        case GroupActions.GroupActionTypes.DeploymentGroupsLoadSuccess:
            return {
                ...state,
                groups: action.payload
            };

        case GroupActions.GroupActionTypes.GroupCreatedSuccess:
            const existingGroup = groupsCloned.find(g => g.id === action.payload.id);
            const indexOfExistingGroup = groupsCloned.indexOf(existingGroup);

            if (indexOfExistingGroup !== -1) {
                groupsCloned[indexOfExistingGroup] = action.payload;
            }
            return {
                ...state,
                groups: groupsCloned
            };

        case GroupActions.GroupActionTypes.GroupUpdated:
            const existing = groupsCloned.find(g => g.id === action.payload.id);
            const indexOfExisting = groupsCloned.indexOf(existing);

            groupsCloned[indexOfExisting] = action.payload;

            return {
                ...state,
                groups: groupsCloned
            };
        case GroupActions.GroupActionTypes.GroupDeleted:
            const existingDeleted = groupsCloned.find(g => g.id === action.payload.id);
            const indexOfExistingDeleted = groupsCloned.indexOf(existingDeleted);

            groupsCloned.splice(indexOfExistingDeleted, 1);

            return {
                ...state,
                groups: groupsCloned
            };

        case deploymentActions.DeploymentActionTypes.SetCurrentDeployment:
            return {
                ...state,
                currentDeploymentId: action.payload.id
            };

        case deploymentActions.DeploymentActionTypes.ClearCurrentDeployment:
            return {
                ...state,
                currentDeploymentId: null
            };

        case deploymentActions.DeploymentActionTypes.InitializeCurrentDeployment:
            return {
                ...state,
                currentDeploymentId: ''
            };

        case deploymentActions.DeploymentActionTypes.DeploymentLoadSuccess:
            const searchEntityState = deploymentEntityAdapter.removeAll(deploymentEntityState);
            const newEntityState = deploymentEntityAdapter.addAll(action.payload.results, searchEntityState);

            const searchState = {
                ...state,
                deploymentsPagedResponse: action.payload,
                deploymentEntityState: {...newEntityState, loading: false, paginationInfo: action.payload.paginationInfo},
                error: ''
            };

            return searchState;

        case deploymentActions.DeploymentActionTypes.DeploymentsPageRequested:
            return {
                ...state,
                deploymentEntityState: {...deploymentEntityState, loading: true}
            };

        case deploymentActions.DeploymentActionTypes.DeploymentsPageLoaded:

            const actionDeployments = action.payload.results;
            const actionPaginationInfo = action.payload.paginationInfo;
            const isLoading = false;

            const entityState = deploymentEntityAdapter.addMany(actionDeployments, deploymentEntityState);

            const newState = {
                ...state,
                deploymentEntityState: {
                    ...entityState,
                    loading: isLoading,
                    paginationInfo: actionPaginationInfo
                },
                error: ''
            };


            return newState;

        case deploymentActions.DeploymentActionTypes.DeploymentLoadFail:
            return {
                ...state,
                error: action.payload
            };

        case deploymentActions.DeploymentActionTypes.DeploymentsPageCancelled:

            return {
                ...state,
                error: action.payload
            };

        case deploymentActions.DeploymentActionTypes.UpdateDeploymentSuccess:
        /*const updatedDeployments = state.deployments.map(
          item => action.payload.id === item.id ? action.payload : item);
        return {
          ...state,
          deployments: updatedDeployments,
          currentDeploymentId: action.payload.id,
          error: ''
        };*/

        case deploymentActions.DeploymentActionTypes.UpdateDeploymentFail:
        /* return {
           ...state,
           error: action.payload
         };*/

        // After a create, the currentDeployment is the new product.
        case deploymentActions.DeploymentActionTypes.CreateDeploymentSuccess:
        /* return {
           ...state,
           deployments: [...state.deployments, action.payload],
           currentDeploymentId: action.payload.id,
           error: ''
         };*/

        case deploymentActions.DeploymentActionTypes.CreateDeploymentFail:
        /* return {
           ...state,
           error: action.payload
         };
    */
        // After a delete, the currentDeployment is null.
        case deploymentActions.DeploymentActionTypes.DeleteDeploymentSuccess:
        /*return {
          ...state,
          deployments: state.deployments.filter(product => product.id !== action.payload),
          currentDeploymentId: null,
          error: ''
        };*/

        case deploymentActions.DeploymentActionTypes.DeleteDeploymentFail:
        /* return {
           ...state,
           error: action.payload
         };*/
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal

} = deploymentEntityAdapter.getSelectors();
