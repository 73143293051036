import {Injectable} from '@angular/core';
import {Settings} from './app.settings.model';

@Injectable()
export class AppSettings {
    public settings = new Settings(
        'Portfolio',   // theme name
        true,       // loadingSpinner
        true,       // fixedHeader
        true,       // sidenavIsOpened
        true,       // sidenavIsPinned
        true,       // sidenavUserBlock
        false,       // sidenavToolsBlock
        'vertical', // horizontal , vertical
        'default',  // default, compact, mini
        'blue-dark',   // indigo-light, red-light, blue-dark
        false       // true = rtl, false = ltr
    );
}

