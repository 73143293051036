<mat-toolbar color="primary" fxlayout="row"
             fxLayoutAlign="start center"
             class="sidenav-header">
    <a mat-button
       matTooltip="Show/Hide About"
       aria-label="Show/Hide About"
       (click)="settings.sidenavToolsBlock = !settings.sidenavToolsBlock"
       fxLayout="row" [fxLayoutAlign]="(settings.menuType=='default') ? 'flex-end flex-end' : 'center center'">
        <button mat-button>About Me</button>
        <mat-icon>arrow_drop_down</mat-icon>
    </a>

    <!-- <svg *ngIf="settings.menuType != 'mini'" class="pin" (click)="settings.sidenavIsPinned = !settings.sidenavIsPinned">
         <path *ngIf="!settings.sidenavIsPinned" d="M16,12V4H17V2H7V4H8V12L6,14V16H11.2V22H12.8V16H18V14L16,12Z"/>
         <path *ngIf="settings.sidenavIsPinned"
               d="M2,5.27L3.28,4L20,20.72L18.73,22L12.8,16.07V22H11.2V16H6V14L8,12V11.27L2,5.27M16,12L18,14V16H17.82L8,6.18V4H7V2H17V4H16V12Z"/>
     </svg>-->

</mat-toolbar>
<div fxLayout="column" fxLayoutAlign="start center" class="tools-block transition-2"
     [class.show]="settings.sidenavToolsBlock">
    <div [fxLayout]="'column'"
         [fxLayoutAlign]="'start center'"
         class="tools-info-wrapper">
        <!-- TOOLS GO HERE-->
         
        <div class="tools-item" (click)="settings.sidenavToolsBlock = !settings.sidenavToolsBlock" >
            <a mat-button [class.selected]="settings.sidenavToolsBlock" fxLayout="row" [fxLayoutAlign]="'start center'">
                <mat-icon>chevron_left</mat-icon> <span class="tools-title"> back</span>
            </a>
        </div>
        <div fxLayout="row" [fxLayoutAlign]="'start center'">
             <app-certifications></app-certifications>
        </div>
    </div>
</div>
<div fxLayout="column" fxLayoutAlign="center center" class="user-block transition-2"
     [class.show]="settings.sidenavUserBlock">
    <div [fxLayout]="(settings.menuType != 'default') ? 'column' : 'row'"
         [fxLayoutAlign]="(settings.menuType != 'default') ? 'center center' : 'space-around center'"
         class="user-info-wrapper">
        <img [src]="userImageUrl" #userImage alt="user-image">
        <div class="user-info" *ngIf="user">
            <p class="name">Welcome {{ user.firstName }}</p>
            <p class="position">
                <small class="muted-text">{{ user.userId}}</small>
            </p>
            <p>
                <br/><button mat-raised-button color="warn" (click)="logout()">Logout</button></p>
        </div>
    </div>
</div>

<div id="sidenav-menu-outer" class="sidenav-menu-outer" perfectScrollbar
     [class.user-block-show]="settings.sidenavUserBlock">
    <span *ngIf="!menuItems">loading store....</span>
    <app-vertical-menu [menuItems]="menuItems" [menuParentId]="0"></app-vertical-menu>
</div>
