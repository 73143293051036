import {Component, ElementRef, HostListener, ViewChild, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'app-fullscreen',
    encapsulation: ViewEncapsulation.None,
    template: `
        <button mat-icon-button class="full-screen">
            <mat-icon *ngIf="!toggle" #expand>fullscreen</mat-icon>
            <mat-icon *ngIf="toggle" #compress>fullscreen_exit</mat-icon>
        </button>
    `
})
export class FullScreenComponent {
    toggle = false;
    @ViewChild('expand') private expand: ElementRef;
    @ViewChild('compress') private compress: ElementRef;

    requestFullScreen(documentElement) {
        if (documentElement.requestFullscreen) {
            documentElement.requestFullscreen();
        } else if (documentElement.webkitRequestFullscreen) {
            documentElement.webkitRequestFullscreen();
        } else if (documentElement.mozRequestFullScreen) {
            documentElement.mozRequestFullScreen();
        } else if (documentElement.msRequestFullscreen) {
            documentElement.msRequestFullscreen();
        } else {
            console.log('Full Screen API is not supported.');
        }
    }

    exitFullScreen() {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        } else {
            console.log('Full Screen API is not supported.');
        }
    }

    @HostListener('click') getFullScreen() {
        if (this.expand) {
            this.requestFullScreen(document.documentElement);
        }
        if (this.compress) {
            this.exitFullScreen();
        }
    }

    @HostListener('window:resize') onFullScreenChange() {
        const fullScreenElement = document.fullscreenElement || document.mozFullScreenElement ||
            document.webkitFullscreenElement || document.msFullscreenElement;
        if (fullScreenElement != null) {
            this.toggle = true;
        } else {
            this.toggle = false;
        }
    }

}
