import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

import {map, take, tap} from 'rxjs/operators';

import {AuthService} from './auth.service';

@Injectable()
export class AuthenticationGuard implements CanActivate {
  constructor(private _authService: AuthService, private _router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {

    return this._authService.isLoggedIn$
      .pipe(
        take(1),
        tap(isLoggedIn => console.log('canActivate  this._authService.isLoggedIn$: ', isLoggedIn)),
        // .shareReplay()
        map(isLoggedIn => {
          return isLoggedIn || this._router.navigate(['/signin']);
        }));
  }

}
