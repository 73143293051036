<div perfectScrollbar class="certifications-content">
    <div fxLayout="row wrap">
        <div *ngFor="let certification of certifications" fxFlex="100" fxFlex.gt-md="33.3" fxFlex.md="50"
            class="flex-p">
            <mat-card class="p-0">
                <div class="bg-primary" fxLayout="row" fxLayoutAlign="space-between center">
                    <h3 class="certification-name">{{certification.name}}</h3>
                </div>

                <div class="certification-content" fxLayout="row" fxLayoutAlign="space-around center">
                    <div fxFlex="20" fxFlex.xs="30" fxLayout="column" fxLayoutAlign="center center">
                        <div class="certification-img">
                            <img [src]="certification.logo" style="max-width: 200px" class="transition-2">
                        </div>

                    </div>

                </div>
            </mat-card>
        </div>
    </div>
</div>