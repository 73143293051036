import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromUserPortal from './user-portal.reducer';
import {DeploymentsQuery} from '../../domain/query-deployments';
import {UserPortalStateSlice} from './user-portal.reducer';

// Selector functions
const getUserPortalFeatureState = createFeatureSelector<fromUserPortal.UserPortalStateSlice>('deployments');

export const getCurrentDeploymentId = createSelector(
  getUserPortalFeatureState,
  state => state.currentDeploymentId
);

export const getCurrentDeployment = createSelector(
  getUserPortalFeatureState,
  getCurrentDeploymentId,
  (state, currentDeploymentId) => {
    if (currentDeploymentId === '') {
      return {
        autoSubmit: false,
        description: '',
        enabled: false,
        fullName: '',
        group: '',
        id: '',
        lastAccessed: '',
        logo: '',
        name: '',
        selected: false,
        tag: '',
        type: '',
        url: '',
        userId: ''
      };
    } else {
      return currentDeploymentId ?  null /*state.deploymentEntityState.ids.find(p => p.id === currentDeploymentId)*/ : null;
    }
  }
);

export const getDeployments = createSelector(
  getUserPortalFeatureState,
  state => state.deploymentEntityState.entities
);

export const getGroups = createSelector(
  getUserPortalFeatureState,
  state => state.groups
);

export const getDeploymentsEntityState = createSelector(
  getUserPortalFeatureState,
  state => state.deploymentEntityState
);

export const getError = createSelector(
  getUserPortalFeatureState,
  state => state.error
);

export const selectPaginationInfo = createSelector(
  getUserPortalFeatureState,
  state => state.deploymentEntityState.paginationInfo
);

export const selectAllDeployments = createSelector(
  getDeploymentsEntityState,
  fromUserPortal.selectAll
);

export const selectDeploymentsPageSelector = (query: DeploymentsQuery) => createSelector(
  selectAllDeployments,
  allDeployments => {

    const start = query.pageNumber * query.pageSize,
      end = start + query.pageSize;

    return allDeployments
      .slice(start, end);

  }
);
