<h1 mat-dialog-title><span>Add Group</span></h1>
<div mat-dialog-content>
    <form [formGroup]="form" class="group-form">
        <mat-tab-group class="vertical-tabs">
            <mat-tab label="Group Name">
                <div fxLayout="column" class="form-field-outer">
                    <mat-form-field class="w-100">
                        <mat-placeholder>
                            <mat-icon>groups</mat-icon>
                            group name
                        </mat-placeholder>
                        <input matInput formControlName="name" required>
                        <mat-error *ngIf="form.controls.name.errors?.required">Group Name is required</mat-error>
                        <mat-error *ngIf="form.controls.name.hasError('minlength')">Group Name isn't long enough,
                            minimum of {{ GROUP_MIN_LENGTH }} characters
                        </mat-error>
                    </mat-form-field>
                </div>
            </mat-tab>

        </mat-tab-group>
    </form>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between center">
    <button mat-raised-button color="warn" (click)="close()">Cancel</button>
    <button mat-raised-button color="primary"
            (click)="saveGroup()"
            [mat-dialog-close]="form.value" [disabled]="!form.valid"><span>
            Create</span></button>

</div>
