<div *ngFor="let menu of parentMenu" perfectScrollbar class="menu-item">
    <a *ngIf="menu.routerLink && !menu.hasSubMenu" mat-button
       fxLayout="row" [fxLayoutAlign]="(settings.menuType=='default') ? 'start center' : 'center center'"
       [routerLink]="[menu.routerLink]" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:true}"
       [matTooltip]="menu.tooltip" matTooltipPosition="after"
       [matTooltipDisabled]="(settings.menuType=='mini') ? 'false' : 'true'"
       (click)="onClick(menu.id)" [id]="'menu-item-'+menu.id">
        <mat-icon class="menu-icon">{{menu.icon}}</mat-icon>
        <span class="menu-title">{{menu.title}}</span>
    </a>
    <a *ngIf="menu.href && !menu.subMenu" mat-button
       fxLayout="row" [fxLayoutAlign]="(settings.menuType=='default') ? 'start center' : 'center center'"
       [attr.href]="menu.href || ''" [attr.target]="menu.target || ''"
       [matTooltip]="menu.tooltip" matTooltipPosition="after"
       [matTooltipDisabled]="(settings.menuType=='mini') ? 'false' : 'true'"
       (click)="onClick(menu.id)" [id]="'menu-item-'+menu.id">
        <mat-icon color="accent" class="menu-icon">{{menu.icon}}</mat-icon>
        <span class="menu-title">{{menu.title}}</span>
    </a>
    <a *ngIf="menu.hasSubMenu" mat-button [ngClass]="{ 'expanded': menu.title === 'Groups'}"
       fxLayout="row" [fxLayoutAlign]="(settings.menuType=='default') ? 'start center' : 'center center'"
       [matTooltip]="menu.tooltip" matTooltipPosition="after"
       [matTooltipDisabled]="(settings.menuType=='mini') ? 'false' : 'true'"
       (click)="onClick(menu.id)" [id]="'menu-item-'+menu.id">
        <mat-icon color="primary" class="menu-icon">{{menu.icon}}</mat-icon>
        <span class="menu-title">{{menu.title}}</span>
        <mat-icon class="menu-expand-icon transition-2">arrow_drop_down</mat-icon>
    </a>

    <div [hidden]="!menu.hasSubMenu" class="sub-menu"
         [ngClass]="{ 'show': menu.title === 'Groups'}"
         [id]="'sub-menu-'+menu.id">
        <app-vertical-menu [menuItems]="menuItems" [menuParentId]="menu.id"></app-vertical-menu>
    </div>
</div>
