import {AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ApplicationUser} from '../../../domain';
import {AuthService} from '../../../services';
import {ChangeDetectorRef} from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-user-menu',
    templateUrl: './user-menu.component.html',
    styleUrls: ['./user-menu.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class UserMenuComponent implements OnInit, AfterViewInit {
    public userImageUrl = '../assets/img/deployments/default-user-icon.jpg';
    public user: ApplicationUser;
    @ViewChild('userImage') private userImageEl: ElementRef;

    constructor(private authService: AuthService,
        private router: Router,
        private changeDetectorRef: ChangeDetectorRef) {

    }

    ngOnInit() {
        // set the current user
        this.authService.currentUser$.subscribe((value) => {
            this.user = value;
        });
        this.authService.isLoggedOut$.subscribe(isLoggedOut => {
            return isLoggedOut && this.router.navigate(['/signin']);
          });
    }

    ngAfterViewInit(): void {
        // set the current user
        if (this.user.userId) {
            this.userImageEl.nativeElement.src =  this.user.icon;
            this.changeDetectorRef.detectChanges();
        }
    }

    logout() {
        this.authService.logout();
    }

}
