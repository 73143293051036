<div class="sidenav-container-wrapper">
    <mat-toolbar color='primary' class="header-toolbar">
        <span class="header-toolbar-text"> Applications</span>
    </mat-toolbar>

    <mat-sidenav-container>
        <mat-sidenav *ngIf="settings.menu == 'vertical'" [opened]="settings.sidenavIsOpened"
            [mode]="(settings.sidenavIsPinned) ? 'side' : 'over'" #sidenav class="sidenav mat-elevation-z6">
            <app-sidenav></app-sidenav>
        </mat-sidenav>
        <mat-sidenav-content perfectScrollbar [disabled]="settings.fixedHeader" class="sidenav-content"
            (psScrollY)="onPsScrollY($event)">
            <mat-toolbar color="primary" class="flex-p-x">
                <div fxLayout="row" fxLayoutAlign="space-between center" class="w-100">
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <button *ngIf="settings.menu == 'vertical'" mat-icon-button 
                        matTooltip="Toggle the left Sidebar."
                        (click)="toggleSidenav()">
                            <mat-icon>menu</mat-icon>
                        </button>
                        <button fxShow="false" fxShow.gt-xs *ngIf="settings.menu == 'vertical'" mat-icon-button
                            matTooltip="Show/Hide User Section in side navigation"
                            aria-label="Show/Hide User Section in side navigation"
                            (click)="settings.sidenavUserBlock = !settings.sidenavUserBlock">
                            <mat-icon>person</mat-icon>
                        </button>
                        
                        <span *ngIf="settings.menu == 'horizontal'" class="small-logo"> <img src="assets/img/icode.png"
                                class="responsive" /></span>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="end center">
                        <div fxLayout="row" fxLayoutAlign="start center" fxShow="false" fxShow.gt-xs class="search-bar">
                            <form method="get">
                                <input matInput #globalSearchInput placeholder="Search your store..."
                                    class="form-field mat-elevation-z3" (keyup.enter)="performSearch()"
                                    [class.show]="toggleSearchBar">
                                <button mat-icon-button (click)="performSearch()" type="button">
                                    <mat-icon>search</mat-icon>
                                </button>
                            </form>
                        </div>
                        <app-flags-menu fxShow="false" fxShow.gt-sm></app-flags-menu>
                        <app-fullscreen></app-fullscreen>
                        <app-applications fxShow="false" fxShow.gt-sm></app-applications>
                        <app-user-menu></app-user-menu>
                        <a href="http://www.linkedin.com/in/olympus" 
                        matTooltip="Click to open my profile on LinkedIn."
                        target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" data-supported-dps="34x34"
                                focusable="false">
                                <g transform="scale(.7083)" fill="none" fill-rule="evenodd">
                                    <rect class="bug-text-color" fill="#FFF" x="1" y="1" width="46" height="46" rx="4">
                                    </rect>
                                    <path
                                        d="M0 4.01A4.01 4.01 0 014.01 0h39.98A4.01 4.01 0 0148 4.01v39.98A4.01 4.01 0 0143.99 48H4.01A4.01 4.01 0 010 43.99V4.01zM19 18.3h6.5v3.266C26.437 19.688 28.838 18 32.445 18 39.359 18 41 21.738 41 28.597V41.3h-7V30.159c0-3.906-.937-6.109-3.32-6.109-3.305 0-4.68 2.375-4.68 6.109V41.3h-7v-23zM7 41h7V18H7v23zm8-30.5a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0z"
                                        class="background" fill="#0077B5"></path>
                                </g>
                            </svg>
                        </a>
                        <app-help-menu></app-help-menu>
                    </div>
                </div>
            </mat-toolbar>

            <mat-toolbar color="primary" *ngIf="settings.menu == 'horizontal'"
                class="horizontal-menu flex-p-x transition-2" [class.sticky]="isStickyMenu"
                [class.fixed-top]="!settings.fixedHeader">
                <div fxLayout="row" fxLayoutAlign="center center" class="w-100">
                    <app-horizontal-menu [menuParentId]="0"></app-horizontal-menu>
                </div>
            </mat-toolbar>

            <div id="main-content" class="inner-sidenav-content transition-2" perfectScrollbar
                [disabled]="!settings.fixedHeader" (psScrollY)="onPsScrollY($event)"
                [class.horizontal-menu-hidden]="isStickyMenu">
                <app-breadcrumb></app-breadcrumb>
                <router-outlet></router-outlet>
            </div>


            <div fxLayout="row" fxLayoutAlign="center center" class="options-icon" (click)="options.toggle()">
                <mat-icon>settings</mat-icon>
            </div>

            <div *ngIf="showBackToTop" fxLayout="row" fxLayoutAlign="center center" class="back-to-top transition-2"
                (click)="scrollToTop();">
                <mat-icon>arrow_upward</mat-icon>
            </div>

        </mat-sidenav-content>

        <mat-sidenav #options position="end" class="options">

            <div fxLayout="row" fxLayoutAlign="center center" class="control mat-elevation-z1">
                <h2>Settings</h2>
            </div>

            <div perfectScrollbar>

                <div fxLayout="column" class="control">
                    <h4>Layout</h4>
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <span>Fixed header</span>
                        <mat-slide-toggle [checked]="settings.fixedHeader"
                            (change)="settings.fixedHeader = !settings.fixedHeader" labelPosition="before">
                        </mat-slide-toggle>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <span>RTL</span>
                        <mat-slide-toggle [checked]="settings.rtl" (change)="settings.rtl = !settings.rtl"
                            labelPosition="before"></mat-slide-toggle>
                    </div>
                </div>

                <div fxLayout="column" class="control">
                    <h4>Choose menu</h4>
                    <mat-radio-group [(ngModel)]="menuOption" (change)="chooseMenu()">
                        <mat-radio-button *ngFor="let menu of menus" [value]="menu">{{menu}}</mat-radio-button>
                    </mat-radio-group>
                </div>

                <div fxLayout="column" class="control">
                    <h4>Choose menu type</h4>
                    <mat-radio-group [(ngModel)]="menuTypeOption" (change)="chooseMenuType()">
                        <mat-radio-button *ngFor="let menuType of menuTypes" [value]="menuType">{{menuType}}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>

                <div fxLayout="column" class="control">
                    <h4>Choose theme skin</h4>
                    <div fxLayout="row" fxLayoutAlign="space-around center">
                        <div class="skin-primary indigo-light" (click)="changeTheme('indigo-light')">
                            <div class="skin-secondary"></div>
                        </div>
                        <div class="skin-primary red-light" (click)="changeTheme('red-light')">
                            <div class="skin-secondary"></div>
                        </div>
                        <div class="skin-primary blue-dark" (click)="changeTheme('blue-dark')">
                            <div class="skin-secondary"></div>
                        </div>

                    </div>
                </div>

                <div fxLayout="column" class="control">
                    <h4>Sidenav options</h4>
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <span>Opened sidenav</span>
                        <mat-slide-toggle [checked]="settings.sidenavIsOpened"
                            (change)="settings.sidenavIsOpened = !settings.sidenavIsOpened" labelPosition="before">
                        </mat-slide-toggle>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <span>Pinned sidenav</span>
                        <mat-slide-toggle [checked]="settings.sidenavIsPinned"
                            (change)="settings.sidenavIsPinned = !settings.sidenavIsPinned" labelPosition="before">
                        </mat-slide-toggle>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <span>Sidenav user info</span>
                        <mat-slide-toggle [checked]="settings.sidenavUserBlock"
                            (change)="settings.sidenavUserBlock = !settings.sidenavUserBlock" labelPosition="before">
                        </mat-slide-toggle>
                    </div>
                </div>

            </div>

        </mat-sidenav>
    </mat-sidenav-container>

    <mat-toolbar color='primary' class="footer-toolbar" responsive>
        <!--heart-pulse-->
        <svg style="width:24px;height:24px" color="primary" viewBox="0 0 24 24">
            <path fill="#008b00"
                d="M7.5,4A5.5,5.5 0 0,0 2,9.5C2,10 2.09,10.5 2.22,11H6.3L7.57,7.63C7.87,6.83 9.05,6.75 9.43,7.63L11.5,13L12.09,11.58C12.22,11.25 12.57,11 13,11H21.78C21.91,10.5 22,10 22,9.5A5.5,5.5 0 0,0 16.5,4C14.64,4 13,4.93 12,6.34C11,4.93 9.36,4 7.5,4V4M3,12.5A1,1 0 0,0 2,13.5A1,1 0 0,0 3,14.5H5.44L11,20C12,20.9 12,20.9 13,20L18.56,14.5H21A1,1 0 0,0 22,13.5A1,1 0 0,0 21,12.5H13.4L12.47,14.8C12.07,15.81 10.92,15.67 10.55,14.83L8.5,9.5L7.54,11.83C7.39,12.21 7.05,12.5 6.6,12.5H3Z" />
        </svg>
        &nbsp;&nbsp;
        <small>Dev 1.0.0.0</small>
    </mat-toolbar>
</div>