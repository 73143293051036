import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Deployment, Group } from '../../../domain';
import { GroupService } from '../../../services/group.service';
import { AppState } from '../../../state/app.state';
import { select, Store } from '@ngrx/store';
import * as fromUserPortal from '../../state/user-portal.selectors';
import {
    AttachGroupUserOption,
    UpdateGroupUserOption
} from '../../state/actions/deployment.actions';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DeploymentGroupsLoad, GroupCreatedSuccess } from '../../state/actions/group.actions';

@Component({
    selector: 'app-user-dialog',
    templateUrl: './group-dialog.component.html',
    styleUrls: ['./group-dialog.component.scss'],
    providers: [GroupService]
})
export class GroupDialogComponent implements OnInit {
    public form: FormGroup;
    public groups: Group[] = [];
    public filteredGroups: Observable<Group[]>;
    public GROUP_MIN_LENGTH = 4;

    constructor(public dialogRef: MatDialogRef<GroupDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public deploymentEntity: Deployment,
        public store: Store<AppState>,
        public groupService: GroupService,
        public fb: FormBuilder) {
        this.form = this.fb.group({
            id: null,
            name: [null, Validators.compose([Validators.required, Validators.minLength(this.GROUP_MIN_LENGTH)])]
        });
    }

    ngOnInit() {
        // GroupDialogComponent for a Deployment
        this.store.pipe(select(fromUserPortal.getGroups))
            .subscribe(next => {
                this.groups = next;
            });

        if (this.deploymentEntity) {
            // do
        } else {
            /* CREATE A GROUP TO HAVE DEPLOYMENTS ATTACHED TO IT*/
        }

        this.filteredGroups = this.form.controls['name'].valueChanges
            .pipe(
                startWith(''),
                map(value => this.filterGroups(value))
            );
    }

    saveGroup(): void {
        const group = new Group();


        const id = this.form.controls['id'].value;
        const name = this.form.controls['name'].value;

        group.name = name;

        // const amendedDeployment = {
        //     ...this.deploymentEntity,
        //     : existingUserOptionsClone
        // };

        // this.deploymentEntity = amendedDeployment;

        // if (hasUserAccessThisGroupAsOption) {
        //     this.store.dispatch(new UpdateGroupUserOption(amendedDeployment));
        // }

        // if (!hasUserAccessThisGroupAsOption) {
        //     const isThisAnExistingGroup = this.groups.find(g => g.name.toLowerCase() === group.name.toLowerCase());
        //     if (!isThisAnExistingGroup) {
        //         // todo Once service returns the group created we can
        //         // chain the actions and not use the service here but canonically in the effects
        //         this.groupService.addEntity(group).subscribe(next => {
        //             console.log(next);
        //             this.store.dispatch(new GroupCreatedSuccess(group));
        //             this.store.dispatch(new DeploymentGroupsLoad());
        //         });
        //     }
        //     this.store.dispatch(new AttachGroupUserOption(amendedDeployment));
        // }

    }

    close(): void {
        this.dialogRef.close();
    }

    private filterGroups(value: string): Group[] {
        const filterValue = value.toLowerCase();

        return this.groups.filter(group => group.name.toLowerCase().includes(filterValue));
    }

}
