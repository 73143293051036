import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {Group} from '../domain/index';
import {AppState} from '../state/app.state';
import {Store} from '@ngrx/store';
import {AppConfigService} from '../_app-config/app-configuration.service';
import {DeploymentsService} from './deployments.service';
import {DeploymentGroupCreated} from '../views/state/actions/group.actions';

@Injectable()
export class GroupService {
    public url = '/groups';
    private BASE_API_URL: string;
    private RESOURCE_URL: string;
    private RESOURCE_REL_URL: string;

    constructor(public http: HttpClient, private store: Store<AppState>,
                private appConfigService: AppConfigService
    ) {
        this.BASE_API_URL = appConfigService._apiUrl;
        this.RESOURCE_URL = `${this.BASE_API_URL}${this.url}`;
        this.RESOURCE_REL_URL = `${this.RESOURCE_URL}`;
    }

    getEntity$(): Observable<Group[]> {
        return this.http.get<Group[]>(this.RESOURCE_REL_URL);
    }

    getEntityById$(groupId: number): Observable<Group> {
        return this.http.get<Group>(this.RESOURCE_REL_URL + `/${groupId}`);
    }

    addEntity(group: Group): Observable<number> {
        // TODO move out of here to effects
        this.store.dispatch(new DeploymentGroupCreated(group));
        return this.http.post<number>(this.RESOURCE_REL_URL, group);
    }


    deleteEntity(group: Group): Observable<Group> {
        return this.http.delete<Group>(this.RESOURCE_REL_URL + `/${group.id}`)
    }

    updateEntity(changes: Partial<Group>) {
        return this.http.put(this.RESOURCE_REL_URL, changes);
    }

    private handleError(error: any) {
        // Eventually we will send the error to remote logging infrastructure
        // and reformat for user consumption
        console.error(error); // log to console instead
        return throwError(error);
    }
}
