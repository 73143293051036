/* NgRx */
import {Action} from '@ngrx/store';
import {Deployment, DeploymentsQuery, PagedResponse} from '../../../domain';

export enum DeploymentActionTypes {
    SetCurrentDeployment = '[Deployment View] Set Current Deployment',
    ClearCurrentDeployment = '[Deployment View] Clear Current Deployment',
    InitializeCurrentDeployment = '[Deployment View] Initialize Current Deployment',
    LoadDeployment = '[Deployment View] Load Single',
    DeploymentsLoad = '[deployments-grid-content.component.ts] Deployments Load',
    DeploymentsPageRequested = '[Deployment View] Deployments Page Requested',
    DeploymentsPageLoaded = '[Deployment View] Deployments Page Loaded',
    DeploymentsPageCancelled = '[Deployment View] Deployments Page Cancelled',
    DeploymentLoadSuccess = '[Deployment View] Deployment Load Success',
    DeploymentLoadFail = '[Deployment View] Deployment Load Fail',
    UpdateGroupUserOption = '[Deployment View] Update Group User Option',
    AttachGroupUserOption = '[Deployment View] Attach Group User Option',
    UpdateDeploymentSuccess = '[Deployment View] Update Deployment Success',
    UpdateDeploymentFail = '[Deployment View] Update Deployment Fail',
    CreateDeployment = '[Deployment View] Create Deployment',
    CreateDeploymentSuccess = '[Deployment View] Create Deployment Success',
    CreateDeploymentFail = '[Deployment View] Create Deployment Fail',
    DeleteDeployment = '[Deployment View] Delete Deployment',
    DeleteDeploymentSuccess = '[Deployment View] Delete Deployment Success',
    DeleteDeploymentFail = '[Deployment View] Delete Deployment Fail'
}

// Action Creators

export class SetCurrentDeployment implements Action {
    readonly type = DeploymentActionTypes.SetCurrentDeployment;

    constructor(public payload: Deployment) {
    }
}

export class ClearCurrentDeployment implements Action {
    readonly type = DeploymentActionTypes.ClearCurrentDeployment;
}

export class InitializeCurrentDeployment implements Action {
    readonly type = DeploymentActionTypes.InitializeCurrentDeployment;
}

export class LoadDeployment implements Action {
    readonly type = DeploymentActionTypes.LoadDeployment;
}

export class DeploymentsLoad implements Action {
    readonly type = DeploymentActionTypes.DeploymentsLoad;

    constructor(public payload: DeploymentsQuery) {
    }
}

export class DeploymentsPageRequested implements Action {
    readonly type = DeploymentActionTypes.DeploymentsPageRequested;

    constructor(public payload: DeploymentsQuery) {
    }
}

export class DeploymentsPageLoaded implements Action {
    readonly type = DeploymentActionTypes.DeploymentsPageLoaded;

    constructor(public payload: PagedResponse<Deployment>) {
    }
}

export class DeploymentsPageCancelled implements Action {
    readonly type = DeploymentActionTypes.DeploymentsPageCancelled;

    constructor(public payload: string) {
        console.error('DeploymentsPageCancelled:', payload);
    }
}

export class DeploymentLoadSuccess implements Action {
    readonly type = DeploymentActionTypes.DeploymentLoadSuccess;

    constructor(public payload: PagedResponse<Deployment>) {
    }
}

export class DeploymentLoadFail implements Action {
    readonly type = DeploymentActionTypes.DeploymentLoadFail;

    constructor(public payload: string) {
    }
}

export class UpdateGroupUserOption implements Action {
    readonly type = DeploymentActionTypes.UpdateGroupUserOption;

    constructor(public payload: Deployment) {
    }
}

export class AttachGroupUserOption implements Action {
    readonly type = DeploymentActionTypes.AttachGroupUserOption;

    constructor(public payload: Deployment) {
    }
}

export class UpdateDeploymentSuccess implements Action {
    readonly type = DeploymentActionTypes.UpdateDeploymentSuccess;

    constructor(public payload: Deployment) {
    }
}

export class UpdateDeploymentFail implements Action {
    readonly type = DeploymentActionTypes.UpdateDeploymentFail;

    constructor(public payload: string) {
    }
}

export class CreateDeployment implements Action {
    readonly type = DeploymentActionTypes.CreateDeployment;

    constructor(public payload: Deployment) {
    }
}

export class CreateDeploymentSuccess implements Action {
    readonly type = DeploymentActionTypes.CreateDeploymentSuccess;

    constructor(public payload: Deployment) {
    }
}

export class CreateDeploymentFail implements Action {
    readonly type = DeploymentActionTypes.CreateDeploymentFail;

    constructor(public payload: string) {
    }
}

export class DeleteDeployment implements Action {
    readonly type = DeploymentActionTypes.DeleteDeployment;

    constructor(public payload: number) {
    }
}

export class DeleteDeploymentSuccess implements Action {
    readonly type = DeploymentActionTypes.DeleteDeploymentSuccess;

    constructor(public payload: number) {
    }
}

export class DeleteDeploymentFail implements Action {
    readonly type = DeploymentActionTypes.DeleteDeploymentFail;

    constructor(public payload: string) {
    }
}

// Union the valid types
export type DeploymentActions = SetCurrentDeployment
    | ClearCurrentDeployment
    | InitializeCurrentDeployment
    | LoadDeployment
    | DeploymentsLoad
    | DeploymentsPageRequested
    | DeploymentsPageLoaded
    | DeploymentsPageCancelled
    | DeploymentLoadSuccess
    | DeploymentLoadFail
    | UpdateGroupUserOption
    | AttachGroupUserOption
    | UpdateDeploymentSuccess
    | UpdateDeploymentFail
    | CreateDeployment
    | CreateDeploymentSuccess
    | CreateDeploymentFail
    | DeleteDeployment
    | DeleteDeploymentSuccess
    | DeleteDeploymentFail;

