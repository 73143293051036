import {EnvironmentConfiguration} from '../../app/_app-config/env-configuration';
import {ANONYMOUS_USER} from '../../app/domain/constants';

export const APP_CONFIG = <EnvironmentConfiguration>{
    'environment': 'Local Dev',
    /* If we want to start developing against the real backend then we need to start ng serve using a proxy file to avoid CORS*/
   /* 'apiBaseUrl': 'http://networkserver.org',*/
    'apiBaseUrl': 'https://w6yq4li625.execute-api.eu-west-2.amazonaws.com/dev'
};

