import {AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {AppSettings} from '../app.settings';
import {Settings} from '../app.settings.model';
import {MenuService} from '../theme/components/menu/menu.service';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../state/layout.reducer';
import {DeploymentsQuery} from '../domain';
import {UserPortalState} from './state/user-portal.reducer';
import {DeploymentsSearch} from './state/actions/search.actions';

@Component({
    selector: 'app-shell',
    templateUrl: './shell.component.html',
    styleUrls: ['./shell.component.scss'],
    providers: []
})
export class ShellComponent implements OnInit, AfterViewInit {

    @ViewChild('sidenav') sidenav: any;
    @ViewChild('globalSearchInput') globalSearchInput: ElementRef;

    public settings: Settings;
    public menus = ['vertical', 'horizontal'];
    public menuOption: string;
    public menuTypes = ['default', 'compact', 'mini'];
    public menuTypeOption: string;
    public isStickyMenu = false;
    public lastScrollTop = 0;
    public showBackToTop = false;
    public toggleSearchBar = true;
    private defaultMenu: string; // declared for return default menu when window resized
    public scrolledContent: any;

    constructor(public appSettings: AppSettings,
                public router: Router,
                private menuService: MenuService,
                private store: Store<UserPortalState>
    ) {
        this.settings = this.appSettings.settings;
    }

    ngOnInit() {
        if (window.innerWidth <= 768) {
            this.settings.menu = 'vertical';
            this.settings.sidenavIsOpened = false;
            this.settings.sidenavIsPinned = false;
        } else {
            this.store.pipe(select(fromRoot.getShowSidenav)
            ).subscribe((sidenavIsOpened: boolean) => {
                this.settings.sidenavIsOpened = !!sidenavIsOpened;
                console.log('this.settings.sidenavIsOpened', this.settings.sidenavIsOpened);
            });

            this.store.pipe(select(fromRoot.getMenuType)
            ).subscribe((menuType: string) => {
                this.settings.menuType = menuType;
                console.log('this.settings.menuType', this.settings.menuType);
            });
        }
        this.menuOption = this.settings.menu;
        this.menuTypeOption = this.settings.menuType;
        this.defaultMenu = this.settings.menu;
    }

    ngAfterViewInit() {
        this.hideSpinner();
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                if (!this.settings.sidenavIsPinned) {
                    this.sidenav.close();
                }
                if (window.innerWidth <= 768) {
                    this.sidenav.close();
                }
            }
        });
        if (this.settings.menu === 'vertical') {
            this.menuService.expandActiveSubMenu(this.menuService.items);
        }
    }

    private hideSpinner() {
        setTimeout(() => {
            this.settings.loadingSpinner = false;
        }, 200);
    }

    public chooseMenu() {
        this.settings.menu = this.menuOption;
        this.defaultMenu = this.menuOption;
        this.router.navigate(['/']);
    }

    public chooseMenuType() {
        this.settings.menuType = this.menuTypeOption;
    }

    public changeTheme(theme) {
        this.settings.theme = theme;
    }

    public toggleSidenav() {
        this.sidenav.toggle();
    }

    public onPsScrollY(event) {
        this.scrolledContent = event.target;
        (this.scrolledContent.scrollTop > 300) ? this.showBackToTop = true : this.showBackToTop = false;
        if (this.settings.menu === 'horizontal') {
            if (this.settings.fixedHeader) {
                const currentScrollTop = (this.scrolledContent.scrollTop > 56) ? this.scrolledContent.scrollTop : 0;
                (currentScrollTop > this.lastScrollTop) ? this.isStickyMenu = true : this.isStickyMenu = false;
                this.lastScrollTop = currentScrollTop;
            } else {
                (this.scrolledContent.scrollTop > 56) ? this.isStickyMenu = true : this.isStickyMenu = false;
            }
        }
    }

    public scrollToTop() {
        const scrollDuration = 200;
        const scrollStep = -this.scrolledContent.scrollTop / (scrollDuration / 20);
        const scrollInterval = setInterval(() => {
            if (this.scrolledContent.scrollTop !== 0) {
                this.scrolledContent.scrollBy(0, scrollStep);
            } else {
                clearInterval(scrollInterval);
            }
        }, 10);
        if (window.innerWidth <= 768) {
            this.scrolledContent.scrollTop = 0;
        }
    }

    @HostListener('window:resize')
    public onWindowResize(): void {
        if (window.innerWidth <= 768) {
            this.settings.sidenavIsOpened = false;
            this.settings.sidenavIsPinned = false;
            this.settings.menu = 'vertical';
        } else {
            (this.defaultMenu === 'horizontal') ? this.settings.menu = 'horizontal' : this.settings.menu = 'vertical';
            this.settings.sidenavIsOpened = true;
            this.settings.sidenavIsPinned = true;
        }
    }

    public closeSubMenus() {
        const menu = document.querySelector('.sidenav-menu-outer');
        if (menu) {
            for (let i = 0; i < menu.children[0].children.length; i++) {
                const child = menu.children[0].children[i];
                if (child) {
                    if (child.children[0].classList.contains('expanded')) {
                        child.children[0].classList.remove('expanded');
                        child.children[1].classList.remove('show');
                    }
                }
            }
        }
    }

    performSearch() {
        const searchTerm = this.globalSearchInput.nativeElement.value;
        const query = new DeploymentsQuery();
        query.searchTerm = searchTerm;

        this.store.dispatch(new DeploymentsSearch(query));
    }

}
