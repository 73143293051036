import {Component, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'app-help-menu',
    templateUrl: './help-menu.component.html',
    styleUrls: ['./help-menu.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class HelpMenuComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
