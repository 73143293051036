import {ActionReducer, ActionReducerMap, MetaReducer} from '@ngrx/store';
import {environment} from '../../environments/environment';
import {storeFreeze} from 'ngrx-store-freeze';
import {routerReducer} from '@ngrx/router-store';
import {userReducer} from './user.reducer';
import {layoutReducer} from './layout.reducer';
import {AppState} from './app.state';

export const appReducers: ActionReducerMap<AppState> = {
    layout: layoutReducer,
    router: routerReducer,
    user: userReducer
};


// console.log all actions
export function logger(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
    return function (state: AppState, action: any): AppState {
        console.log('AppState', state);
        console.log('Action', action);

        return reducer(state, action);
    };
}

export const metaReducers: MetaReducer<AppState>[] = !environment.production
    ? [/*logger,*/ storeFreeze]
    : [];
