import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService, AuthDidFailPayload } from './../../services/auth.service';
import { NgForm, FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import {MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import { RegistrationSheetComponent } from './registration-sheet/registration-sheet.component';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit {

  didFail = false;
  isLoading = false;

  loginForm = new FormGroup({
    username: new FormControl(),
    password: new FormControl()
  });

  userRegistrationForm = new FormGroup({
    username: new FormControl(),
    email: new FormControl(),
    password: new FormControl(),
    confirmPassword: new FormControl()
  });

  confirmForm = new FormGroup({
    validationUsername: new FormControl(),
    validationCode: new FormControl()
  });

  constructor(private authService: AuthService,
    private snackBar: MatSnackBar,
    private bottomSheet: MatBottomSheet) {
  }

  ngOnInit() {
    this.authService.authDidFail.subscribe((result: AuthDidFailPayload) => {
      if (result.message !== 'initialised') {
        this.snackBar.open(result.message,
          result.status ? 'failure' : 'success',
          {
            duration: 5000,
          });
      }
    });
  }

  onSubmitLogin(formValue: { username: string, password: string }) {
    this.authService.signIn(formValue.username, formValue.password);
  }

  onSubmitRegistration(formValue: { username: string, email: string, password: string }) {
    this.authService.signUp(formValue.username, formValue.email, formValue.password);
  }

  onConfirm(formValue: { validationUsername: string, validationCode: string }) {
    this.authService.confirmUser(formValue.validationUsername, formValue.validationCode);
  }

  openBottomSheet(): void {
    this.bottomSheet.open(RegistrationSheetComponent);
  }
}
