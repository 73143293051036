import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {PaginationPipe} from './pagination/pagination.pipe';
import {ProfilePicturePipe} from './profilePicture/profilePicture.pipe';
import {ChatPersonSearchPipe} from './search/chat-person-search.pipe';
import {EntitySearchPipe} from './search/entity-search.pipe';
import {TruncatePipe} from './truncate/truncate.pipe';
import {MailSearchPipe} from './search/mail-search.pipe';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        PaginationPipe,
        ProfilePicturePipe,
        ChatPersonSearchPipe,
        EntitySearchPipe,
        TruncatePipe,
        MailSearchPipe
    ],
    exports: [
        PaginationPipe,
        ProfilePicturePipe,
        ChatPersonSearchPipe,
        EntitySearchPipe,
        TruncatePipe,
        MailSearchPipe
    ]
})
export class PipesModule {
}
