<button mat-icon-button [matMenuTriggerFor]="appsMenu" #appsMenuTrigger="matMenuTrigger">
    <mat-icon>apps</mat-icon>
</button>
<mat-menu #appsMenu="matMenu" [overlapTrigger]="false" class="toolbar-dropdown-menu applications">
    <span (mouseleave)="appsMenuTrigger.closeMenu()">
        <mat-toolbar color="primary">
            <div fxLayout="row" fxLayoutAlign="space-around center" class="header">
               Favourite Applications
            </div>  
        </mat-toolbar>
        <mat-card>           
            <mat-grid-list cols="3" rowHeight="1:1">
                <mat-grid-tile>
                  <button mat-button>
                      <mat-icon class="muted-text">computer</mat-icon>
                      <span>Atlas</span>
                  </button>
                </mat-grid-tile>
                <mat-grid-tile>
                  <button mat-button>
                      <mat-icon class="muted-text">computer</mat-icon>
                      <span>Isotrak</span>
                  </button>
                </mat-grid-tile>
                <mat-grid-tile>
                  <button mat-button>
                      <mat-icon class="muted-text">computer</mat-icon>
                      <span>IFRS</span>
                  </button>
                </mat-grid-tile>
                <mat-grid-tile>
                  <button mat-button>
                      <mat-icon class="muted-text">computer</mat-icon>
                      <span>TOTE</span>
                  </button>
                </mat-grid-tile>
                <mat-grid-tile>
                  <button mat-button>
                      <mat-icon class="muted-text">computer</mat-icon>
                      <span>BAML</span>
                  </button>
                </mat-grid-tile>
                <mat-grid-tile>
                  <button mat-button>
                      <mat-icon class="muted-text">computer</mat-icon>
                      <span>HRS</span>
                  </button>
                </mat-grid-tile>
            </mat-grid-list>
        </mat-card>
    </span>
</mat-menu>
