import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {EnvironmentConfiguration} from './env-configuration';

@Injectable()
export class AppConfigService {

    private _config: EnvironmentConfiguration;

    constructor(private httpClient: HttpClient, @Inject('Window') private _window: any) {
        // Return the protocol, hostname and port number of a URL + the configuration EndPoint
        this._baseUrl = this.getBaseUrl();
        this._configUrl = environment.config.apiBaseUrl + '/configuration';
        this._apiUrl = this.getApiUrl();
    }

    public _baseUrl: string;
    public _configUrl: string;
    public _apiUrl: string;

    public loadEnvironmentConfig(): Promise<EnvironmentConfiguration> {

        return new Promise((resolve) => {
            this.httpClient.get(
                this._configUrl).toPromise()
                .then((configResponse: EnvironmentConfiguration) => {
                    this._config = configResponse;
                    resolve(configResponse);
                })
                .catch((error) => {
                    console.error('[AppConfigService] could not get application configuration !', error);
                    resolve(error);
                });
        });
    }

    public getConfig(): EnvironmentConfiguration {
        return this._config;
    }

    private getBaseUrl(): string {
        return this.getOriginUrl() ; //+ environment.config.virtualDirectory;
    }

    private getOriginUrl(): string {
        let origin = '';

        origin = `${this._window.location.protocol}`
            + `//${this._window.location.hostname}${window.location.port ? ':' + window.location.port : ''}`;

        // return origin;
        return environment.config.apiBaseUrl;
    }

    private getConfigUrl(): string {
        return this.getOriginUrl() + '/configuration';
    }

    private getApiUrl(): string {
        return environment.config.apiBaseUrl;
    }

}

export function LoadConfigurationFn(configService: AppConfigService) {
    return () => configService.loadEnvironmentConfig();
}
