import {ApplicationUser} from '../domain';
import {UserActions, UserActionTypes} from './user.actions';
import {ANONYMOUS_USER} from '../domain/constants';

export interface UserState {
  showUserEmail: boolean;
  currentUser: ApplicationUser;
}

const initialUserState: UserState = {
  showUserEmail: false,
  currentUser: ANONYMOUS_USER
};

export function userReducer(state = initialUserState, action: UserActions): UserState {
  switch (action.type) {
    case UserActionTypes.ShowUserEmail:
      return {
        ...state,
        showUserEmail: action.payload
      };

    case UserActionTypes.SetCurrentUser:
      return {
        ...state,
        currentUser: action.payload
      };

    default:
      return state;
  }
}
