import {Component, OnInit, AfterViewInit} from '@angular/core';
import {AppSettings} from './app.settings';
import {Settings} from './app.settings.model';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
    public settings: Settings;

    constructor(public appSettings: AppSettings) {
        this.settings = this.appSettings.settings;
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        this.hideSpinner();
    }

    private hideSpinner() {
        setTimeout(() => {
            this.settings.loadingSpinner = false;
        }, 200);
    }
}
