import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';

import {GroupDialogComponent} from '../views/deployments/deployment-group-dialog/group-dialog.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {PipesModule} from '../theme/pipes/pipes.module';
import {HttpClientModule} from '@angular/common/http';
import {InMemoryDeploymentsDataService} from './inMemoryDeploymentsDataService';
import {InMemoryWebApiModule} from 'angular-in-memory-web-api';
import {LaunchDeploymentModalComponent} from '../views/deployments/deployment-launch-dialog/launch-deployment-modal.component';
import {GroupCreateDialogComponent} from '../views/deployments/group-create-dialog/group-create-dialog.component';
import {GroupService} from '../services/group.service';
import {MenuService} from '../theme/components/menu/menu.service';
import {GroupsEditDialogComponent} from '../views/deployments/groups-edit-dialog/groups-edit-dialog.component';
import {RouterModule} from '@angular/router';

import { MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatGridListModule} from '@angular/material/grid-list';

import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatNativeDateModule} from '@angular/material/core';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatRippleModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatStepperModule} from '@angular/material/stepper';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';

export const COMPONENTS = [
    GroupDialogComponent,
    GroupCreateDialogComponent,
    GroupsEditDialogComponent,
    LaunchDeploymentModalComponent
];

export const MODULES = [
    CommonModule,
    RouterModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    FlexLayoutModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    MatBottomSheetModule
];


const PROVIDERS = [
    GroupService,
    MenuService
];


@NgModule({
    imports: [...MODULES
        /*, InMemoryWebApiModule.forRoot(InMemoryDeploymentsDataService, {delay: 150, put204: false})*/
    ],
    exports: [...MODULES],
    providers: [...PROVIDERS],
    declarations: [...COMPONENTS],
    entryComponents: [...COMPONENTS],
})

export class SharedModule {
    static forRoot() {
        return {
            ngModule: SharedModule,
            providers: [...PROVIDERS],
        };
    }
}
