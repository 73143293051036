import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {Deployment, PagedResponse} from '../domain/index';
import {AppConfigService} from '../_app-config/app-configuration.service';
import {DeploymentsQuery} from '../domain/query-deployments';

@Injectable()
export class DeploymentsService {
    public url = '/apps';
    public optionUrl = `/api/user/deployment/`;
    private BASE_API_URL: string;
    private RESOURCE_URL: string;
    private RESOURCE_REL_URL: string;
    private OPTION_REL_URL: string;

    private PAGE_SIZE = 3;

    constructor(private http: HttpClient,
                private appConfigService: AppConfigService) {

        this.BASE_API_URL = appConfigService._apiUrl;
        this.RESOURCE_URL = `${this.BASE_API_URL}${this.url}`;
        this.RESOURCE_REL_URL = `${this.RESOURCE_URL}`;
        this.OPTION_REL_URL = `${this.optionUrl}`;
    }

    getEntity$(): Observable<PagedResponse<Deployment>> {
        const endPoint = this.RESOURCE_REL_URL + `?pageNumber=0&pageSize=100`;
        return this.http.get<PagedResponse<Deployment>>(endPoint);
    }

    getEntitiesByName$(name: string): Observable<PagedResponse<Deployment>> {

        const endPoint = this.RESOURCE_REL_URL + `?filter=${name}&pageNumber=0&pageSize=100`;
        return this.http.get<PagedResponse<Deployment>>(endPoint);
    }

    addEntity(deployment: Deployment) {
        return this.http.post(this.RESOURCE_REL_URL, deployment);
    }

    updateEntity(deployment: Deployment): Observable<Deployment> {
        this.http.put(this.RESOURCE_REL_URL, deployment);
        return of(deployment);
    }

    cancelAccess(deployment: Deployment) {
        return this.updateEntity(deployment);
    }


    loadDeployments$(query: DeploymentsQuery): Observable<PagedResponse<Deployment>> {

        const filters: {
            filter: string,
            deploymentId: string
        } = {
            filter: null,
            deploymentId: null
        };

        const searchTerm = query.searchTerm;
        const searchBy = query.searchBy;

        const pageSize = query.pageSize;
        const pageNumber = query.pageNumber;

        const sortOrder = query.sortOrder;
        const sortBy = query.sortBy;

        if (searchTerm) {
            const field = searchBy || 'name';

            if (field.toLowerCase() === 'name') {
                filters.filter = searchTerm;
            } else {
                filters.deploymentId = searchTerm;
            }
        }

        let filtersString = '';

        Object.entries(filters).forEach(
            ([key, value]) => {
                filtersString += key + '=' + value + '&';
            }
        );

        const url = this.RESOURCE_REL_URL;

        const httpParams = new HttpParams()
            .set('pageNumber', (pageNumber ? pageNumber.toString() : '0'))
            .set('pageSize', (pageSize ? pageSize.toString() : this.PAGE_SIZE.toString()));

        if (searchTerm) {
            httpParams .set('search', searchTerm);
        }
        if (sortBy) {
            httpParams .set('order_by', searchTerm);
        }
         if (sortOrder) {
            httpParams .set('sort_by', searchTerm);
        }

        if (filters.deploymentId) {
            httpParams.set('deploymentId', filters.deploymentId);
        }

        if (filters.filter) {
            httpParams.set('filter', filters.filter);
        }

        return this.http.get<PagedResponse<Deployment>>(url, {
            params: httpParams
        });
    }
}
