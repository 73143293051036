import {LayoutActionsUnion, LayoutActionTypes} from './layout.actions';
import {createFeatureSelector, createSelector} from '@ngrx/store';

export interface LayoutState {
    headerFixed: boolean;
    isMenuVertical: boolean;
    sidenavIsOpened: boolean;
    menuType: string;
    skin: string;
    rtl: boolean;
}

const initialLayoutState: LayoutState = {
    headerFixed: true,
    isMenuVertical: true,
    sidenavIsOpened: true,
    menuType: 'default',
    skin: 'default',
    rtl: false
};

export function layoutReducer(
    state: LayoutState = initialLayoutState,
    action: LayoutActionsUnion
): LayoutState {
    switch (action.type) {
        case LayoutActionTypes.CloseSidenav:
            return {
                ...state,
                sidenavIsOpened: false,
            };

        case LayoutActionTypes.OpenSidenav:
            return {
                ...state,
                sidenavIsOpened: true,
            };

        case LayoutActionTypes.SetMenuType:
            return {
                ...state,
                menuType: action.menuType,
            };

        case LayoutActionTypes.SetRtl:
            return {
                ...state,
                rtl: action.rtl,
            };

        case LayoutActionTypes.SetSkin:
            return {
                ...state,
                skin: action.skin,
            };

        case LayoutActionTypes.ToggleMenuVertical:
            return {
                ...state,
                isMenuVertical: action.isMenuVertical,
            };

        default:
            return state;
    }
}

const getLayoutState = createFeatureSelector<LayoutState>('layout');

export const getShowSidenav = createSelector(
    getLayoutState,
    (state: LayoutState) => {
        const groupName = state.sidenavIsOpened;
        return groupName;
    }
);

export const getMenuType = createSelector(
    getLayoutState,
    (state: LayoutState) => {
        const menuType = state.menuType;
        return menuType;
    }
);

export const getMenuSkin = createSelector(
    getLayoutState,
    (state: LayoutState) => {
        const skin = state.skin;
        return skin;
    }
);

export const getIsRtl = createSelector(
    getLayoutState,
    (state: LayoutState) => {
        const isRtl = state.rtl;
        return isRtl;
    }
);

export const getIsMenuVertical = createSelector(
    getLayoutState,
    (state: LayoutState) => {
        const isVertical = state.isMenuVertical;
        return isVertical;
    }
);
