import {ApplicationUser} from './application-user';

export const ANONYMOUS_USER: ApplicationUser = {
    userId: undefined,
    icon: undefined,
    firstName: undefined,
    lastName: undefined,
    fullName: undefined,
    email: undefined,
    entitlements: undefined
};

export const DEFAULT_USER: ApplicationUser = {
    userId: 'zk1mij9',
    icon: 'assets/img/icon.jpg',
    firstName: 'Byron',
    lastName: 'Thanopoulos',
    fullName: 'Byron Thanopoulos',
    email: 'vyron.thanopoulos@gmail.com',
    entitlements: null
};
