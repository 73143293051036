import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-certifications',
  templateUrl: './certifications.component.html',
  styleUrls: ['./certifications.component.scss']
})
export class CertificationsComponent implements OnInit {

  certifications = [
    { name: 'MCAD', logo: '/assets/img/certifications/mcad.png', title: 'Microsoft Certified Applications Developer' },
    { name: 'MCT', logo: '/assets/img/certifications/mct.png', title: 'Microsoft Certified Trainer' },
    { name: 'MCDBA', logo: '/assets/img/certifications/mcdba.png', title: 'Microsoft Certified Database Administrator' },
    { name: 'CSM', logo: '/assets/img/certifications/csm.png', title: 'Certified Scrum Master' },
    { name: 'TOGAF', logo: '/assets/img/certifications/togaf.png', title: 'The Open Group Archtecture Framework' }
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
