import {AfterViewInit, Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {AppSettings} from '../../../../app.settings';
import {Settings} from '../../../../app.settings.model';
import {MenuService} from '../menu.service';
import {Menu} from '../menu.model';

@Component({
    selector: 'app-vertical-menu',
    templateUrl: './vertical-menu.component.html',
    styleUrls: ['./vertical-menu.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: []
})
export class VerticalMenuComponent implements OnInit, AfterViewInit {
/*TODO: Refactor auto na min einai cycliko*/
    constructor(public appSettings: AppSettings,
                public menuService: MenuService,
                public router: Router) {
        this.settings = this.appSettings.settings;
    }

    @Input('menuItems') menuItems;
    @Input('menuParentId') menuParentId;

    parentMenu: Array<Menu>;
    public settings: Settings;

    ngOnInit() {
        // console.log('vertical call:', new Date());
        // console.log(this.menuItems, new Date());
        this.parentMenu = this.menuItems.filter(item => item.parentId === this.menuParentId);
    }

    ngAfterViewInit() {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                if (this.settings.fixedHeader) {
                    const mainContent = document.getElementById('main-content');
                    if (mainContent) {
                        mainContent.scrollTop = 0;
                    }
                } else {
                    document.getElementsByClassName('mat-drawer-content')[0].scrollTop = 0;
                }
            }
        });
    }

    onClick(menuId) {
        this.menuService.toggleMenuItem(menuId);
        this.menuService.closeOtherSubMenus(this.menuItems, menuId);
    }

}
