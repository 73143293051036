export class PaginationInfo {

    public pageSize: number;
    public pageNumber: number;

    public totalItems: number;
    public totalPages: number;

    public startItem: number;
    public endItem: number;

}
