import {AfterViewInit, Component, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {AppSettings} from '../../../../app.settings';
import {Settings} from '../../../../app.settings.model';
import {MenuService} from '../menu.service';
import {MatMenuTrigger} from '@angular/material/menu';

@Component({
    selector: 'app-horizontal-menu',
    templateUrl: './horizontal-menu.component.html',
    styleUrls: ['./horizontal-menu.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: []
})
export class HorizontalMenuComponent implements OnInit, AfterViewInit {

    @Input('menuParentId') menuParentId;
    public menuItems: Array<any>;
    public settings: Settings;
    @ViewChild(MatMenuTrigger, {static: false}) trigger: MatMenuTrigger;

    constructor(public appSettings: AppSettings, public menuService: MenuService, public router: Router) {
        this.settings = this.appSettings.settings;
    }

    ngOnInit() {
        this.menuItems = this.menuService.getHorizontalMenuItems();
        this.menuItems = this.menuItems.filter(item => item.parentId === this.menuParentId);
    }

    ngAfterViewInit() {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                if (this.settings.fixedHeader) {
                    const mainContent = document.getElementById('main-content');
                    if (mainContent) {
                        mainContent.scrollTop = 0;
                    }
                } else {
                    document.getElementsByClassName('mat-drawer-content')[0].scrollTop = 0;
                }
            }
        });
    }

}
