<h1 mat-dialog-title><span>
    <mat-icon class="va-sub">group_work</mat-icon>
    Edit Groups</span></h1>
<div mat-dialog-content class="groups-edit-mat-dialog">
    <div fxLayout="row" fxLayoutAlign="space-around center">
        <div fxFlex="100" class="flex-p">
            <mat-card [hidden]="!(groups.length > 0)" class="p-0">
                <mat-table  #table [dataSource]="dataSource" matSort class="mat-elevation-z8">
                    <ng-container matColumnDef="id">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Group Id</mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.id}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="name">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Name</mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <mat-form-field class="group-input-field" *ngIf="element.id === editIndexId">
                                <input matInput [readonly]="element.id !== editIndexId"
                                       class=""
                                       placeholder="Group Name"
                                       (change)="updateElements(element, $event.srcElement.value)"
                                       [value]="element.name" matInput>
                            </mat-form-field>
                            <span *ngIf="element.id !== editIndexId">
                                <a routerLink="/deployments/group/{{ element.name }}"
                                   routerLinkActive="active-link"
                                   [routerLinkActiveOptions]="{exact:true}"
                                >{{ element.name }}</a>   </span>
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="actions">
                        <mat-header-cell *matHeaderCellDef>
                            Add new &nbsp;
                            <button
                                    matTooltip="Add Group"
                                    aria-label="Add Group"
                                    mat-raised-button mat-icon-button color="primary" (click)="openCreateGroupDialog()">
                                <mat-icon class="muted-text">add</mat-icon>
                            </button>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element">

                            <button mat-raised-button color="primary" class="m-10 md-small"
                                    matTooltip="Edit Group {{ element.name }}"
                                    aria-label="Edit Group"
                                    *ngIf="element.id !== editIndexId" (click)="startEdit(element)">Edit
                            </button>
                            <button mat-raised-button color="primary" class="m-10 md-small"
                                    matTooltip="Cancel Edit"
                                    aria-label="Cancel Edit"
                                    *ngIf="element.id === editIndexId" (click)="stopEdit(element)">Cancel
                            </button>
                            <button mat-raised-button color="primary"
                                    *ngIf="element.id === editIndexId"
                                    matTooltip="Commit Edit"
                                    aria-label="Commit Edit"
                                    class="md-small"
                                    (click)="save(element)">Save
                            </button>

                            <button (click)="delete(element)"
                                    *ngIf="element.id !== editIndexId"
                                    matTooltip="Delete"
                                    aria-label="Delete"
                                    mat-raised-button mat-icon-button
                                    class="delete-group"
                                    color="warn">
                                <mat-icon>clear</mat-icon>
                            </button>

                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                </mat-table>
                <mat-paginator fxLayout="row" #paginator [pageSize]="5"
                               [pageSizeOptions]="[5, 10, 20, 30]"></mat-paginator>
            </mat-card>
        </div>
    </div>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between center">
    <button mat-raised-button color="warn" (click)="close()">Close</button>
</div>
